// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import React from 'react'

import './Loading.css'

function Loading(props) {
    return (
        <div className="loading-animation-container">
            <div className="loader"></div>
        </div>
    )
}

export default Loading
