// Copyright © FINANCE SECURITY GmbH - All rights reserved.


export const month = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre']
export const days = ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi']

export default function date () {
    const days = ['Di','Lu','Ma','Me','Je','Ve','Sa']

    return [month,days]
}