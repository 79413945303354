import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import useLoadTranslation from "../../hooks/useLoadTranslation";

import { getPractitionerRoleNextAppointment } from "../../api/restApiRequests/organization/getPractitionerRoleNextAppointment"

function useGetDoctorEventForNotification(props) {
    const [doctorEventList, setDoctorEventList] = useState([])
    const translation = useLoadTranslation("UseGetDoctorEventForNotification");

    useEffect(() => {
        if (props.practitionerRoleId) {
            getPractitionerFutureAppointmentsEvents()
        }
        // eslint-disable-next-line
    }, [props.practitionerRoleId])


    const getPractitionerFutureAppointmentsEvents = async () => {
        try {
            const d = await getFutureAppointments()

            if (!d || !d.success) {
                throw new Error(d ? d.error : "Error graphql query")
            }

            // Format and return list of future appointments
            const futureAppointments = d.data.map(appointment => {
                const patientDisplayName = `${appointment.patient.name.given} ${appointment.patient.name.family}`

                return ({
                    eventName: `${translation.infoText} "${appointment.reasonCode}" ${translation.infoText1} ${patientDisplayName}`,
                    startTime: appointment.start,
                    remindersTime: [{ time: 5, unit: "minute" }]
                })
            })

            setDoctorEventList(futureAppointments)

        }
        catch (e) {
            setDoctorEventList([])
        }
    }

    const getFutureAppointments = async () => {
        try {
            const d = await getPractitionerRoleNextAppointment({
                type: "next24h",
                practitionerRoleId: props.practitionerRoleId
            })


            if (!d || !d.success) {
                throw new Error(d ? d.error : "Error graphql query")
            }

            return ({ success: true, data: d.data })
        }
        catch (e) {
            setPageNotification({ isNotification: true, type: "error", message: translation.errorNotif });
            return ({ success: false })
        }
    }

    return { doctorEventList }
}

useGetDoctorEventForNotification.defaultProps = {
    practitionerRoleId: null,
}

useGetDoctorEventForNotification.propTypes = {
    practitionerRoleId: PropTypes.string,
}

export default useGetDoctorEventForNotification