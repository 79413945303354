// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import React, { useEffect, useState, useRef, useMemo, lazy, Suspense } from "react";
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../context/auth";
import { useNotifications } from "../../context/notifications";
import { useLocale } from "../../context/locale";
import { useDisplaySpecialScreenContext } from "context/displaySpecialScreen"

import useWindowSize from "../../hooks/useWindowSize";
import useLoadTranslation from "../../hooks/useLoadTranslation";

import { isUrlLocaleValid } from "appUtils"
import { ReactComponent as NewAppointmentDoctorIcon } from "../../utils/icons/NewAppointmentIcon.svg";
import { ReactComponent as FolderIcon } from "../../utils/icons/FolderIcon.svg";
import { ReactComponent as NewAppointmentPatientIcon } from "../../utils/icons/PatientNewAppointmentCalendar.svg";
import { ReactComponent as VideoIcon } from "../../utils/icons/VideoIcon.svg";
import { ReactComponent as EFolderIcon } from "../../utils/icons/EFolderIcon.svg";
import { ReactComponent as BlueTimelineIcon } from "../../utils/icons/BlueTimelineIcon.svg";
import { ReactComponent as RoomOverviewIcon } from "../../utils/icons/RoomOverviewIcon.svg";
import { ReactComponent as HomeIcon } from '../../utils/icons/HomeBlueIcon.svg'
import { ReactComponent as DoctorCalendar } from '../../utils/icons/DoctorCalendar.svg'
import { ReactComponent as DoctorHome } from '../../utils/icons/DoctorHome.svg'
import { ReactComponent as PatientListIcon } from '../../utils/icons/PatientListIcon.svg'
import { ReactComponent as MessageIcon } from '../../utils/icons/Message.svg'
import { ReactComponent as BarChartIcon } from "../../utils/icons/BarChartIcon.svg";

import Loading from "components/utils/Loading";
const RequireAuth = lazy(() => import("../utils/routing/RequireAuth"));
const LeftBarMenu = lazy(() => import("../Toolbar/LeftBarMenu"));
const MobileFooter = lazy(() => import("../utils/MobileFooter"));
const PublicFooter = lazy(() => import("../Toolbar/PublicFooter"))
const Toolbar = lazy(() => import("../Toolbar/Toolbar"));
const MobileToolbar = lazy(() => import("../Toolbar/MobileToolbar"));
const PublicToolbar = lazy(() => import('../Toolbar/PublicToolbar'))
const CookieConsent = lazy(() => import("../MainContentPages/publicPages/CookieConsent/CookieConsent"))

import "../Toolbar/LeftBarMenu.css"

function PageWrapper(props) {
  const { userData, user } = useAuth();
  const translation = useLoadTranslation("LeftBarMenu");
  const location = useLocation()
  const navigate = useNavigate()
  const { locale } = useLocale()
  const { notifications, refreshNotifications } = useNotifications()
  const { displaySpecialScreen, setDisplaySpecialScreen } = useDisplaySpecialScreenContext()
  const shouldDisplayCookieBanner = displaySpecialScreen.cookieConsentBanner
  let screenSize = useWindowSize();

  const mediaView = useMemo(() => {
    if (!screenSize) {
      return "laptop"
    }

    return (screenSize.width <= 600 ? "mobile" : "desktop")
  }, [screenSize])

  const navItemFooter = useMemo(() => {
    // Practitioner icons
    if (userData.practitionerRoleId) {
      return ([
        {
          icon: <PatientListIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/patientList`) },
          title: translation.patientList
        },
        {
          icon: <DoctorHome className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/`) },
          title: translation.home
        },
        {
          icon: <DoctorCalendar className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/scheduler`) },
          title: translation.scheduler
        },
      ])
    }

    // Patient icons
    if (userData.role === "Patient") {
      return ([
        {
          icon: <FolderIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/documentManagement`); },
          title: translation.patientDocumentManagement
        },
        {
          icon: <HomeIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/`); },
          title: translation.home
        },
        {
          icon: <NewAppointmentPatientIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/appointment`); },
          title: translation.newAppointment
        }
      ])
    }
    // eslint-disable-next-line
  }, [userData, translation])

  const navItems = useMemo(() => {
    // Practitioner icons
    if (userData.practitionerRoleId && userData?.active) {
      return ([
        {
          icon: <DoctorHome className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/`) },
          title: translation.home,
          type: "home"
        },
        {
          icon: <PatientListIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/patientList`) },
          title: translation.patientList,
          type: "patientList"
        },
        {
          icon: <DoctorCalendar className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/scheduler`) },
          title: translation.scheduler,
          type: "scheduler"
        },
        {
          icon: <NewAppointmentDoctorIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/appointment`) },
          title: translation.newAppointment,
          type: "newAppointment"
        },
        {
          icon: <BarChartIcon className="lbm-icon stroke-green" />,
          handleClick: () => { navigate(`/${locale}/dashboard`) },
          title: translation.dashboard,
          type: "dashboard"
        },
        {
          icon: <VideoIcon className="lbm-icon stroke-green" />,
          handleClick: () => { navigate(`/${locale}/videoCall`) },
          title: translation.videoCall,
          type: "videoCall"
        },
        {
          icon: <MessageIcon className="lbm-icon stroke-green" />,
          handleClick: () => { navigate(`/${locale}/chat`) },
          title: translation.chat,
          type: "chat"
        },
        {
          icon: <RoomOverviewIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/roomList`) },
          title: translation.roomOverview,
          type: "roomOverview"
        },
      ])
    }

    // Patient icons
    if (userData.role === "Patient") {
      return ([
        {
          icon: <HomeIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/`); },
          title: translation.home,
          type: "home"
        },
        {
          icon: <MessageIcon className="lbm-icon stroke-green" />,
          handleClick: () => { navigate(`/${locale}/chat`) },
          title: translation.chat,
          type: "chat"
        },
        {
          icon: <FolderIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/documentManagement`); },
          title: translation.patientDocumentManagement,
          type: "documentManagement"
        },
        {
          icon: <NewAppointmentPatientIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/appointment`); },
          title: translation.newAppointment,
          type: "newAppointment"
        },
        {
          icon: <VideoIcon className="lbm-icon stroke-green" />,
          handleClick: () => { navigate(`/${locale}/videoCall`) },
          title: translation.videoCall,
          type: "videoCall"
        },
        {
          icon: <EFolderIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/patient/eFolder/${user.patientId}`); },
          title: translation.eFolder,
          type: "eFolder"
        },
        {
          icon: <BlueTimelineIcon className="lbm-icon" />,
          handleClick: () => { navigate(`/${locale}/patientSideTimeline`); },
          title: translation.timeline,
          type: "timeline"
        },
      ])
    }
    // eslint-disable-next-line
  }, [userData, user, translation])

  // Refresh notifications on page change
  useEffect(() => {
    refreshNotifications()
    // eslint-disable-next-line
  }, [location])

  // Check that URL locale is valid. If not, redirect to "en-us" URL 
  useEffect(() => {
    if (!isUrlLocaleValid()) {
      const urlNoLocale = window.location.pathname.split('/').slice(2).join("/")
      navigate(`/${locale}/${urlNoLocale}`)
    }
    // Do not add "navigate" to dependency array
    // eslint-disable-next-line
  }, [window.location.pathname])

  const displayToolbar = () => {
    if ((!props.privatePage && !userData.role) || !userData.active) {
      // Public page
      return (
        <PublicToolbar notificationData={notifications} />
      )
    }

    // Private page - desktop view
    else if (mediaView === "desktop") {
      return (
        <Toolbar notificationData={notifications} />
      )
    }

    // Private page - mobile view
    return (
      <MobileToolbar
        notificationData={notifications}
        navItems={navItems}
        locale={locale}
      />
    )
  }

  const displayFooter = (isLeftBarActive) => {
    const shouldDisplayMobileFooter = isLeftBarActive.includes("mobile") && mediaView === "mobile"
    const shouldDisplayPublicFooter = !props.privatePage

    return (
      <>
        {shouldDisplayMobileFooter &&
          <MobileFooter
            contentArray={navItemFooter}
          />
        }
        {shouldDisplayPublicFooter &&
          <PublicFooter />
        }
      </>
    )
  }

  const shouldDisplayLeftBarFromUrl = () => {
    // If location matches list of location where we should display, then display
    const urlNoLocale = window.location.pathname.split('/').slice(2).join("/")

    const listOfUrls = [{
      url: "",
      roles: ["Patient", "Practitioner"],
      type: ["mobile", "desktop"]
    },
    {
      url: "patientList",
      roles: ["Practitioner"],
      type: ["mobile", "desktop"]
    },
    {
      url: "roomList",
      roles: ["Practitioner"],
      type: ["mobile", "desktop"]
    },
    {
      url: "scheduler",
      roles: ["Practitioner"],
      type: ["mobile", "desktop"]
    },
    {
      url: "patient/eFolder/:id",
      roles: ["Practitioner", "Patient"],
      type: ["mobile", "desktop"]
    },
    {
      url: "roomView/:roomId",
      roles: ["Practitioner"],
      type: ["mobile", "desktop"]
    },
    {
      url: "appointment/:id/:id",
      roles: ["Practitioner", "Patient"],
      type: ["mobile", "desktop"]
    },
    {
      url: "appointment",
      roles: ["Practitioner", "Patient"],
      type: ["mobile", "desktop"]
    },
    {
      url: "RoomList",
      roles: ["Practitioner"],
      type: ["mobile", "desktop"]
    },
    {
      url: "documentManagement",
      roles: ["Patient"],
      type: ["mobile", "desktop"]
    },
    {
      url: "patientSideTimeline",
      roles: ["Patient"],
      type: ["mobile", "desktop"]
    },
    {
      url: "doctorOverview/:id",
      roles: ["Patient"],
      type: ["mobile", "desktop"]
    },
    {
      url: "chat",
      roles: ["Practitioner", "Patient"],
      type: ["mobile", "desktop"]
    },
    {
      url: "dashboard",
      roles: ["Practitioner"],
      type: ["mobile", "desktop"]
    },
    {
      url: "videoCall",
      roles: ["Practitioner", "Patient"],
      type: ["mobile", "desktop"]
    }
    ]

    //if urlNoLocale is part of listOfUrls, then display leftBarMenu (also on mobile), if not do not display
    const isLeftBarActive = listOfUrls.find((url) => {

      const splitUrl = url.url.split("/").filter((part) => part.length)
      const splitCurrentUrl = urlNoLocale.split("/").filter((part) => part.length)

      if (splitUrl.length !== splitCurrentUrl.length) {
        return false
      }

      for (let i = 0; i < splitUrl.length; i++) {
        if (splitUrl[i] !== splitCurrentUrl[i] && splitUrl[i][0] !== ":") {
          return false
        }
      }

      if (!userData?.role) {
        return false
      }

      return url.roles.includes(userData?.role)
    })

    return isLeftBarActive?.type ?? []
  }

  const isLeftBarActive = shouldDisplayLeftBarFromUrl()

  return (
    <Suspense fallback={<Loading />}>
      <RequireAuth
        key={location.key}
        privatePage={props.privatePage}
        activeOnly={props.activeOnly}
        requiredRole={props.privatePage ? props.requiredRole : ["all"]}
      >
        {(isLeftBarActive.includes("desktop") && mediaView === "desktop" && navItems?.length) &&
          <LeftBarMenu navItems={navItems} />
        }

        {/* Cookie banner */}
        {shouldDisplayCookieBanner &&
          <CookieConsent />
        }

        {/* Main content */}
        <div id="pageWrapperContainer" className="wrapper" style={props.mainContentStyle}>
          {displayToolbar()}
          <main className={(isLeftBarActive.includes("desktop") && navItems?.length) ? "wrapper-content-leftbar" : "wrapper-content"}>
            {props.children}
          </main>
          {displayFooter(isLeftBarActive)}
        </div>
      </RequireAuth>
    </Suspense>
  );
}

PageWrapper.propTypes = {
  privatePage: PropTypes.bool,
  activeOnly: PropTypes.bool,
  mainContentStyle: PropTypes.object,
  requiredRole: PropTypes.array
};

PageWrapper.defaultProps = {
  privatePage: true,
  activeOnly: true,
  mainContentStyle: {},
  requiredRole: ["all"], //If no required role, everyone can access the route
};

export default PageWrapper;
