// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import * as process from 'process';
(window).global = window;
(window).process = process;
(window).Buffer = [];

// render micro frontend function
window.renderDocappyFront = (containerId) => {
  const rootMF = createRoot(document.getElementById(containerId));
  rootMF.render(<App />)
  serviceWorker.unregister();
};

// unmount micro frontend function
window.unmountDocappyFront = containerId => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};

// Mount to root if it is not a micro frontend
if (!document.getElementById('DocappyFront-container')) {
  const root = createRoot(document.getElementById('root'));
  root.render(<App />)
}

// StrictMode can cause unintended behaviours in dev mode

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();