// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: {
//     practitionerRoleId: ID!
//      type: oneOf["next", "next24h", "today"] (default to "next")
// }
export const getPractitionerRoleNextAppointment = async (data) => {
    return axiosWrapper({
        data,
        method: "get",
        url: "organization/practitionerRole/nextAppointment"
    })
}