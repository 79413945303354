// Copyright © FINANCE SECURITY GmbH - All rights reserved.

function useGetClientInfo() {

    const getClientInfo = () => {
        const info = {
            timeOpened: new Date(),
            timezone: (new Date()).getTimezoneOffset() / 60,

            pageon: window.location.pathname,
            referrer: document.referrer,
            browserName: navigator.appName,
            browserEngine: navigator.product,
            browserVersion1a: navigator.appVersion,
            browserVersion1: navigator.userAgent,
            browserLanguage: navigator.language,
            browserOnline: navigator.onLine,
            browserPlatform: navigator.platform,
            javaEnabled: navigator.javaEnabled(),
            dataCookiesEnabled: navigator.cookieEnabled,
            dataCookies1: document.cookie,
            dataCookies2: decodeURIComponent(document.cookie.split(";")),
            dataStorage: localStorage,

            sizeScreenW: window.screen.width,
            sizeScreenH: window.screen.height,
            sizeDocW: document.width,
            sizeDocH: document.height,
            sizeInW: window.innerWidth,
            sizeInH: window.innerHeight,
            sizeAvailW: window.screen.availWidth,
            sizeAvailH: window.screen.availHeight,
            scrColorDepth: window.screen.colorDepth,
            scrPixelDepth: window.screen.pixelDepth,
        };

        return info
    }

    return { getClientInfo }
}

export default useGetClientInfo
