// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils';

// data: {
// docappyId: ID!
// practitionerRoleId: ID || null
// }
export const getNotification = async (data) => {
    return axiosWrapper({
        data,
        method: "get",
        url: "notification"
    })
}