// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import axios from 'axios';
import fileDownload from 'js-file-download';

export const axiosInstance = axios.create({
    withCredentials: true
})

export const axiosWrapper = async ({ url, method, data, isFile = false, isGetFile = false }) => {
    switch (method) {
        case "get":
            try {
                let resGet = await axiosInstance({
                    method: 'get',
                    url: `${process.env.REACT_APP_DOCAPPY_RESTAPI_SERVER_URI}/${url}`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    params: data,
                })

                return ({ success: true, data: resGet.data.data, response: resGet })
            }
            catch (e) {
                const errorReason = e?.response?.data?.errorReason ?? "unknownReason"
                return ({ success: false, error: e, errorReason: errorReason })
            }

        case "post":
            try {
                let resPost = await axiosInstance({
                    method: 'post',
                    url: `${process.env.REACT_APP_DOCAPPY_RESTAPI_SERVER_URI}/${url}`,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': isFile ? 'multipart/form-data' : 'application/json'
                    },
                    data: isFile ? data : JSON.stringify(data),
                    body: isFile ? data : JSON.stringify(data)
                })

                if (!resPost.data || !resPost.data.success) {
                    throw new Error("Request failed");
                }

                return (resPost.data)
            }
            catch (e) {
                const errorReason = e?.response?.data?.errorReason ?? "unknownReason"
                return ({ success: false, error: e, errorReason: errorReason })
            }
        default:
            return ({ success: false, error: "Wrong method type provided", errorReason: "invalidParameters" })

    }
}


export const axiosWrapperDownloadFile = async ({ url, data, shouldDownload = false, extension }) => {
    if (shouldDownload) {
        data.shouldDownload = true
    }

    try {
        let resGet = await axiosInstance({
            method: 'get',
            url: `${process.env.REACT_APP_DOCAPPY_RESTAPI_SERVER_URI}/${url}`,
            responseType: 'blob',
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            params: data,
        })

        if (!resGet) {
            throw new Error("Request failed");
        }

        if (shouldDownload) {
            fileDownload(resGet.data, data.fileName ? `${data.fileName}` : "file")
        }

        // Handle preparing the document to show on frontend
        if (["DCM"].includes(extension)) {
            return ({ success: true, data: { isFile: true, file: resGet.data, extension: extension } })
        }

        // Check if blob is not a file (if no download and no preview available for example)
        try {
            const dataUnblobed = JSON.parse(await resGet.data.text());

            return ({ success: true, data: { isFile: false, data: "NO DATA" } })
        }
        catch (e) {
            // If blob is a file, will return error, proceed
        }

        // Contain the file as a blob
        return ({ success: true, data: { isFile: true, file: resGet.data, extension: extension } })
    }
    catch (e) {
        const errorReason = e?.response?.data?.errorReason ?? "unknownReason"
        return ({ success: false, error: e, errorReason: errorReason })
    }
}