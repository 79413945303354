import React, { useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom';

import Loading from 'components/utils/Loading'

import { LocaleContext } from "context/locale";
import { useAuth } from "context/auth"
import { usePageNotification } from 'context/pageNotification';

import { postLanguage } from "api/restApiRequests/patient/postLanguage"

import { getLocaleFromUrl } from 'appUtils'

import { loadDayjsLocale } from 'utils/utilsDateManagement'
import { localeArray } from "utils/localization"


function LocaleProvider(props) {
    const { userData, user } = useAuth()
    const { setPageNotification } = usePageNotification()
    const location = useLocation()

    const [locale, setLocale] = useState(getLocaleFromUrl({}))

    const translation = useMemo(() => {
        return require(`data/locale/${locale}/App.json`);
    }, [locale])

    // On load, load the locale from the URL
    useEffect(() => {
        const urlLocale = getLocaleFromUrl({})
        setLocaleFunction(urlLocale)
        loadDayjsLocale(urlLocale)

        // eslint-disable-next-line
    }, [])

    // If URL do not match state saved locale, change to URL locale
    useEffect(() => {
        checkLocale()
        // Do not add checkLocale to dependencies, it will cause infinite loop
        // eslint-disable-next-line
    }, [location, locale])


    // TODO - check why is called twice
    const checkLocale = async () => {
        try {
            if (!location) {
                return
            }

            const urlLocale = location.pathname?.split('/')[1]
            const isUrlLocaleCorrect = localeArray.includes(urlLocale)

            //If locale is not same as URL or URL locale is not correct
            if (urlLocale !== locale || !isUrlLocaleCorrect) {
                // Correct locale: we update in backend for patient
                if (isUrlLocaleCorrect) {
                    setLocaleFunction(urlLocale);
                    if (userData?.role === "Patient" && user?.patientId) {
                        const d = await postLanguage({
                            authorId: user.patientId,
                            language: urlLocale
                        })

                        if (!d?.success) {
                            throw new Error(d?.error ?? "Error setting language")
                        }
                    }
                }
                else {
                    //If saved locale in localStorage, retrieve it
                    const storageLocale = localStorage.getItem("docappyLocale")
                    if (storageLocale && localeArray.includes(storageLocale)) {
                        setLocaleFunction(storageLocale);
                        return
                    }

                    //Else use browserlanguage
                    const browserLanguage = (window.navigator.userLanguage || window.navigator.language).toLowerCase()
                    if (localeArray.includes(browserLanguage)) {
                        setLocaleFunction(browserLanguage);
                        return
                    }

                    //If browserLanguage not supported, default is english
                    setLocaleFunction("en-us");
                }
            }
        }
        catch (e) {

        }
    }

    const setLocaleFunction = async (newLocale) => {
        loadDayjsLocale(newLocale)
        setLocale(newLocale)
        localStorage.setItem("docappyLocale", newLocale)
    }


    if (!locale) {
        return (
            <Loading />
        )
    }

    return (
        <LocaleContext.Provider
            value={{
                locale: locale,
                setLocale: setLocaleFunction,
            }}>
            {props.children}
        </LocaleContext.Provider>
    )
}

export default LocaleProvider