// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// token is given using cookies
export const postLogout = async () => {
    return axiosWrapper({
        data: {},
        method: "post",
        url: "identity/logout"
    })
}