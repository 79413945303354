import React, { useState } from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { DisplaySpecialScreenContext } from "context/displaySpecialScreen";
import { PageNotificationContext } from "context/pageNotification";


import EventReminder from './EventReminder';

import NotificationProvider from 'providers/NotificationProvider';
import AuthProvider from 'providers/AuthProvider';
import LocaleProvider from 'providers/LocaleProvider';
import UserPreferencesProvider from 'providers/UserPreferencesProvider';
import AppointmentSearchParamsContextProvider from 'providers/AppointmentSearchParamsContextProvider';
import ChatProvider from 'providers/ChatProvider';

// Provide the context and handle some app-wide state
function ContextProvider(props) {
    const [pageNotification, setPageNotification] = useState({
        isNotification: false,
        type: "",
        message: "",
        shouldAutomaticallyClose: true,
        closeAfterTime: 5000
    });

    const [chatNotification, setChatNotification] = useState({
        isNotification: false,
        type: "",
        message: "",
        shouldAutomaticallyClose: true,
        closeAfterTime: 5000
    });

    const [displaySpecialScreen, setDisplaySpecialScreen] = useState({
        hasRefreshedSpecialScreens: false,
        organizationConfiguration: false,
        practitionerRoleConfiguration: false,
        cookieConsentBanner: false
    })

    // #####################################
    // # Render functions
    // #####################################

    return (
        <DndProvider backend={HTML5Backend}>
            <DisplaySpecialScreenContext.Provider
                value={{
                    displaySpecialScreen: displaySpecialScreen,
                    setDisplaySpecialScreen: setDisplaySpecialScreen
                }}
            >
                <PageNotificationContext.Provider
                    value={{
                        pageNotification: pageNotification,
                        setPageNotification: setPageNotification,
                        chatNotification: chatNotification,
                        setChatNotification: setChatNotification
                    }}
                >
                    <AuthProvider>
                        <LocaleProvider>
                            <UserPreferencesProvider>
                                <NotificationProvider>
                                    <AppointmentSearchParamsContextProvider>
                                        <EventReminder>
                                            <ChatProvider>
                                            {props.children}
                                            </ChatProvider>
                                        </EventReminder>
                                    </AppointmentSearchParamsContextProvider>
                                </NotificationProvider>
                            </UserPreferencesProvider>
                        </LocaleProvider>
                    </AuthProvider>
                </PageNotificationContext.Provider>
            </DisplaySpecialScreenContext.Provider>
        </DndProvider>
    )
}

export default ContextProvider