// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { localeArray } from './utils/localization'
import CryptoJS from 'crypto-js';


export const getLocaleFromUrl = ({ returnOnlyValid = true }) => {
  let localeUrl = window.location.pathname.split('/')[1]

  if (returnOnlyValid) {
    if (localeArray.includes(localeUrl)) {
      return localeUrl
    }
    return ("en-us")
  }

  return localeUrl
}

export const isUrlLocaleValid = () => {
  let localeUrl = getLocaleFromUrl({ returnOnlyValid: false })
  return localeArray.includes(localeUrl)
}

export const encryptString = (str) => {
  const encrypted = CryptoJS.HmacSHA256(str, process.env.REACT_APP_DOCAPPY_RESTAPI_ENCRYPT_KEY).toString()
  return encrypted
}
