// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: {
// authorId: patientId! || practitionerRoleId!
// conversationId: ID!
// offset: Int
// }

export const getUserChatMessages = async (data) => {
    return axiosWrapper({
        data,
        method: "get",
        url: "user/chat/chat"
    })
}