// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: {
// token: String!,
// authorId: id!,
// newPreferences: Object,
// type: String! (Which options are available ?),
// id: id!,
// }


export const setPreferences = async (data) => {
    return axiosWrapper({
        data,
        method: "post",
        url: "preferences"
    })
}