// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import { useMemo } from "react";

// A custom hook that builds on useLocation to parse
// the query string for you.
export default function useUriQueryParams(location) {

    const res = useMemo(() =>
        new URLSearchParams(location.search),
        [location])

    return res
}