import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import useLoadTranslation from "../hooks/useLoadTranslation";

import { usePageNotification } from '../context/pageNotification'

const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

function useSendNotificationCountdown(props) {
    const { setPageNotification } = usePageNotification()
    const translation = useLoadTranslation("UseSendNotificationCountdown");

    const [filteredEventList, setFilteredEventList] = useState([])
    const [nextNotification, setNextNotification] = useState(null) //{reminderTime: DateTime!, event: Event!}

    useEffect(() => {
        const interval = setInterval(() => {
            filterEvents() // Filter events that are not yet over
            checkIfReminderIsDue()
        }, 60000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [filteredEventList, nextNotification]);

    // Get next reminder based on filtered event list
    useEffect(() => {
        getNextReminder()
        // eslint-disable-next-line
    }, [filteredEventList])

    const filterEvents = () => {
        const listToFilter = filteredEventList.length > 0 ? filteredEventList : props.eventList

        // Only keep events that still have a reminder remaining in the future 
        const filteredList = listToFilter.filter(event => {
            const timeRemaining = getTimeRemaining(event)

            if (timeRemaining < 0) {
                return false
            }

            for (let reminder of event.remindersTime) {
                if (dayjs().add(reminder.time, reminder.unit).isBefore(dayjs(event.startTime))) {
                    return true
                }
            }

            return false
        })

        setFilteredEventList(filteredList)
    }

    const getNextReminder = () => {
        let nextReminder = null

        for (let event of filteredEventList) {
            for (let reminder of event.remindersTime) {
                const reminderTime = dayjs(event.startTime).subtract(reminder.time, reminder.unit).startOf("minute")
                if (reminderTime.isAfter(dayjs())) {
                    if (nextReminder === null || reminderTime.isBefore(nextReminder.reminderTime)) {
                        nextReminder = {
                            reminderTime: reminderTime,
                            event: event
                        }
                    }
                }
            }
        }

        setNextNotification(nextReminder)
    }

    const checkIfReminderIsDue = () => {
        if (nextNotification === null) {
            return
        }

        if (dayjs().isAfter(dayjs(nextNotification.reminderTime))) {
            setPageNotification({
                isNotification: true,
                message: nextNotification.event.customMessage ?? `${translation.okNotif} ${nextNotification.event.eventName} ${translation.okNotif1} ${dayjs(nextNotification.event.startTime).fromNow()}`,
                type: 'ok',
                shouldAutomaticallyClose: true,
                closeAfterTime: 60000
            })
            getNextReminder()
        }
    }

    const getTimeRemaining = (event) => {
        const timeLeft = dayjs(event.startTime).diff(dayjs(), 'seconds')
        if (timeLeft > 0) {
            return timeLeft
        } else {
            return 0
        }
    }
}

useSendNotificationCountdown.propTypes = {
    eventList: PropTypes.array
    // array of Event :
    // { 
    // 	eventName: String
    // 	startTime: DateTime
    // 	remindersTime: [{ time: int, unit: oneOf["minute", "hour", "day"] }, {..}]
    // 	customMessage: String(translated already)
    // }
}

useSendNotificationCountdown.defaultProps = {
    eventList: []
}

export default useSendNotificationCountdown