// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import "react-datepicker/dist/react-datepicker.css";

import { RoutesComponent } from './RoutesComponent';
import ErrorBoundary from './components/ErrorBoundary';
import ContextProvider from './ContextProvider';

// import RenderOnAnonymous from './components/utils/RenderOnAnonymous';
// import RenderOnAuthenticated from 'components/utils/RenderOnAuthenticated';

export function App(props) {
  return (
    <BrowserRouter>
      <ContextProvider>
        <ErrorBoundary>
          <RoutesComponent />
        </ErrorBoundary>
      </ContextProvider>
    </BrowserRouter>
  )
}

export default App;
