import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { AuthContext } from "context/auth";
import { useDisplaySpecialScreenContext } from "context/displaySpecialScreen";

import useGetUserData from "hooks/graphqlOperations/useGetUserData";

import { postCheckAuthToken } from "api/restApiRequests/user/postCheckAuthToken"
import { postLogout } from "api/restApiRequests/user/postLogout"

import { loadTheme } from "utils/utilsFunctions"

function AuthProvider(props) {
    const navigate = useNavigate()
    const { setDisplaySpecialScreen } = useDisplaySpecialScreenContext()
    const [user, setUser] = useState({ loading: true })

    const [userRole, setUserRole] = useState(JSON.parse(localStorage.getItem("DocappyRole")) ?? {})
    const [loading, setLoading] = useState(true)

    const setUserFunction = useCallback((user = { loading: false }) => {
        let role
        if (user.patientId) {
            role = "Patient"
        }
        if (user.practitionerId) {
            role = "Practitioner"
        }
        if (user.id === "admin") {
            role = "admin"
        }

        loadTheme(role)

        localStorage.setItem("DocappyToken", JSON.stringify(user));
        setUser(user)
    }, [])

    const setUserRoleFunction = useCallback(({ role, practitionerRoleId }) => {
        setUserRole({ role, practitionerRoleId })
        localStorage.setItem("DocappyRole", JSON.stringify({ role, practitionerRoleId }));
    }, [])

    const logout = async () => {
        try {
            const d = await postLogout()

            if (!d?.success) {
                throw new Error("Failed to logout")
            }
        }
        catch (e) {
        }
        finally {
            setDisplaySpecialScreen({
                hasRefreshedSpecialScreens: false,
                organizationConfiguration: false,
                practitionerRoleConfiguration: false,
                cookieConsentBanner: false
            })
            // Remove tokens from localStorage what allow application to know who you are
            localStorage.removeItem("DocappyToken");
            localStorage.removeItem("DocappyRole")
            // Remove information from state what allow application to know who you are
            setUserFunction();
            setUserRole()
            // Will redirect you to your correct language. This is a workaround because we don't have access to the locale in AuthProvider
            navigate(`/e/`, {
                replace: false,
            })
        }
    }

    const userData = useGetUserData({ user, roleData: userRole, loading, logout: setUserFunction })

    const checkIfUserIsAuthenticated = async (thenSetUser = true, logoutOnFail = true, shouldRefreshData = true) => {
        let storedUser = JSON.parse(
            localStorage.getItem("DocappyToken")
        )

        if (!storedUser || (!storedUser.patientId && !storedUser.practitionerId)) {
            if (logoutOnFail) {
                logout()
            }
            else {
                setUserFunction()
            }
            setLoading(false)
            return ({})
        }

        else {
            try {
                const isTokenValid = await postCheckAuthToken({ shouldRefreshData })

                if (!isTokenValid || !isTokenValid.success) {
                    throw new Error("Token is expired or not valid")
                }

                let newUser = {
                    id: isTokenValid.data.preferred_username,
                    email: isTokenValid.data.email,
                    patientId: isTokenValid.data.patientId,
                    practitionerId: isTokenValid.data.practitionerId,
                    practitionerRolesId: isTokenValid.data.practitionerRolesId,
                    picture: isTokenValid.data.picture,
                    loading: false
                }

                if (thenSetUser) {
                    setUserFunction(newUser)
                }
                setLoading(false)
                return (newUser)
            }
            catch (e) {
                // Error message displayed where this function is called
                logout()
                setLoading(false)
                return ({})
            }
        }
    }

    // // On component mount, load saved "User" and localePreference
    // BUG : cause user to be redirected to homepage when typing manually URL of a public page. Currently disabled and only called when user is on a private page
    useEffect(() => {
        checkIfUserIsAuthenticated(true, false)
        // eslint-disable-next-line
    }, [])

    // Load website theme based on role of authenticated user
    useEffect(() => {
        loadTheme(userData.role)
    }, [userData])

    return (
        <AuthContext.Provider
            value={{
                user,
                userData,
                setUserFunction,
                setUserRoleFunction,
                logout,
                checkIfUserIsAuthenticated
            }}
        >
            {props.children}
        </ AuthContext.Provider>
    )
}

export default AuthProvider