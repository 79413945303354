// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import useGetClientInfo from "./useGetClientInfo.js"
import { useAuth } from "../context/auth";

function useEventLogging() {
    const { getClientInfo } = useGetClientInfo()
    const { user, userData } = useAuth();

    // ########################
    // GraphQL operations
    // ########################

    const createDocappyLogMutation = async (variables = {}) => {
        try {
            const d = null
            // const d = await postGraphqlMutation({
            //     operationName: "CreateDocappylogInUseeventlogging",
            //     variables: variables
            // })

            // postGraphqlMutation deprecated, here is previously used mutation : 

            // CreateDocappylogInUseeventlogging: `
            // mutation CreateDocappylogInUseeventlogging(
            //     $logName: String
            //     $eventId: Int
            //     $level: Int
            //     $user: String
            //     $computer: String
            //     $message: String
            // ) {
            //         createDocappyLogs(
            //             input: [
            //             {
            //                 logName: $logName
            //             eventId: $eventId
            //             level: $level
            //             user: $user
            //             computer: $computer
            //             message: $message
            //             }
            //         ]
            //         ) {
            //         docappyLogs {
            //                 createdAt
            //                 id
            //             }
            //         }
            //     }
            // `

            if (!d || !d.success) {
                throw new Error(d ? d.error : "Error graphql query")
            }

            return ({ success: true, data: d.data })
        }
        catch (e) {
            // TODO log + error handling
            return ({ success: false })
        }
    }

    // ########################
    // 
    // ########################

    const eventLogging = async (eventId, logDb = false, error = null, tentative = 1) => {
        try {
            let logLevelsAsInteger = await (await import("../data/logLevels.json"))
            const logLevel = eventId.toString()[0]
            const maxLevelLogged = logLevelsAsInteger[process.env.REACT_APP_DOCAPPY_FRONTEND_LOG_LEVEL] || 4   // 4 = INFO
            const shouldEventBeLogged = logLevel <= maxLevelLogged

            if (shouldEventBeLogged) {
                const eventLevel = {
                    1: "FATAL",
                    2: "ERROR",
                    3: "WARN",
                    4: "INFO",
                    5: "DEBUG",
                    6: "TRACE"
                }
                let eventData = await (await import("../data/eventLogs.js"))[`event${eventId}`]

                const clientInfo = await getClientInfo()

                const userInfo = {
                    id: user.id ?? "User not authenticated",
                    email: user.email ?? "User not authenticated",
                    loggedAs: userData.role ?? "Other/Not authenticated",
                }

                const computerInfo = {
                    browserLanguage: clientInfo.browserLanguage,
                    browserPlatform: clientInfo.browserPlatform,
                    pageon: clientInfo.pageon
                }

                if (logDb) {
                    // TODO : think how to secure and do rate limiting to prevent frontend user bombarding backend with log request 

                    // await createDocappyLogMutation({
                    //     logName: eventData.name,
                    //     eventId: eventId,
                    //     level: eventData.level,
                    //     user: JSON.stringify(userInfo),
                    //     computer: JSON.stringify(computerInfo),
                    //     message: error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : eventData.message
                    // })
                }

                const logMessageRestApi = `${eventLevel[logLevel]} || ${logLevel} || ${userInfo.email}: ${eventData.name} ${error ? JSON.stringify(error, Object.getOwnPropertyNames(error)) : eventData.message}`

                // let hasSuccessfullyPostedLogRestApiServer = await postLogRestApi(logMessageRestApi, eventId)
                // if (!hasSuccessfullyPostedLogRestApiServer.data === true) {
                //     throw new Error("Failed to post log to REST API server")
                // }
            }
        }
        catch (e) {
            // If logging fails, try again (3 times total) then try logging that event logging failed
            if (tentative === 3) {
                const errorMessage = JSON.stringify({ message: `Failed to log event ${eventId}`, error: e })
                eventLogging(200000, userData, false, errorMessage, tentative + 1)
            } else if (tentative < 4) {
                eventLogging(eventId, userData, logDb, error, tentative + 1)
            }
        }
    }

    return { eventLogging }

}

export default useEventLogging