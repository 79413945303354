// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import React, { useEffect, lazy, Suspense, useState } from "react";
import { Routes, Navigate, Route, useLocation, useNavigate } from "react-router-dom";

import Loading from "components/utils/Loading";

import { useLocale } from "context/locale";
import { useAuth } from "context/auth";
import { usePageNotification } from "context/pageNotification";

import useUriQueryParams from "hooks/useUriQueryParams";
import PageWrapper from "components/MainContentPages/PageWrapper";
import PrivacyPolicy from "components/MainContentPages/publicPages/PrivacyPolicy";

const LandingPage = lazy(() => import("components/MainContentPages/publicPages/LandingPage"));
const DoctorHomePage = lazy(() => import("components/MainContentPages/DoctorHomePage/DoctorHomePage"));
const DoctorConfiguration = lazy(() => import("components/MainContentPages/DoctorConfiguration/DoctorConfiguration"));
const PatientHomePage = lazy(() => import("components/MainContentPages/PatientHomePage/PatientHomePage"));
const NotFound = lazy(() => import("components/MainContentPages/NotFound/NotFound"));
const Login = lazy(() => import("components/MainContentPages/publicPages/Login"));
const UserProfile = lazy(() => import("components/MainContentPages/UserProfilePage/UserProfile"));
const PatientInformation = lazy(() =>
  import("components/MainContentPages/PatientInformation/PatientInformation")
);
const NewDoctorRegistration = lazy(() => import("components/MainContentPages/publicPages/NewDoctorRegistration"));
const FullScheduler = lazy(() => import("components/MainContentPages/AppointmentScheduler/FullScheduler"));
const NewAppointment = lazy(() => import("components/MainContentPages/NewAppointmentDoctor/NewAppointment"));
const NewAppointmentPatient = lazy(() => import("components/MainContentPages/NewAppointmentPatient/NewAppointmentPatient"));
const AppointmentDetails = lazy(() => import("components/MainContentPages/AppointmentDetails/AppointmentDetails"));
const NotificationToolbar = lazy(() => import("components/Toolbar/NotificationToolbar"));
const RoomOverview = lazy(() => import("components/MainContentPages/RoomOverview/RoomOverview"));
const NotificationCenter = lazy(() => import("components/MainContentPages/NotificationCenter/NotificationCenter"));
const About = lazy(() => import("components/MainContentPages/About/About"));
const TermsConditions = lazy(() => import("components/MainContentPages/TermsConditions/TermsConditions"));
const LearnMoreDoctor = lazy(() => import("components/MainContentPages/LearnMoreDoctor/LearnMoreDoctor"));
const ContactPage = lazy(() => import("components/MainContentPages/ContactPage/ContactPage"));
const ForPatients = lazy(() => import("components/MainContentPages/MoreInformationPatients/MoreInformationPatients"));
const Help = lazy(() => import("components/MainContentPages/Help/Help"));
const DocumentManagementContainer = lazy(() => import("components/MainContentPages/DocumentManagement/DocumentManagementContainer"));
const PatientSideTimeline = lazy(() => import("components/MainContentPages/PatientHomePage/PatientSideTimeline"));
const RoomList = lazy(() => import("components/MainContentPages/RoomOverview/RoomList"));
const CreateOrganization = lazy(() => import("components/MainContentPages/CreateOrganization/CreateOrganization"));
const ResetPassword = lazy(() => import("components/MainContentPages/ResetPassword/ResetPassword"));
const RecoverEmail = lazy(() => import("components/MainContentPages/RecoverEmail/RecoverEmail"));
const ResetPasswordForm = lazy(() => import("components/MainContentPages/ResetPassword/ResetPasswordForm"));
const ConfirmEmailForm = lazy(() => import("components/MainContentPages/ConfirmEmailForm/ConfirmEmailForm"));
const DoctorDisplayPatientResult = lazy(() => import("components/MainContentPages/DoctorHomePage/DoctorDisplayPatientResult"));
const DashboardPage = lazy(() => import("components/MainContentPages/Dashboard/DashboardPage"))
const VideoCallMenu = lazy(() => import("components/MainContentPages/VideoCall/VideoCallMenu"))
const Chat = lazy(() => import("components/MainContentPages/Chat/Chat"))
const DoctorOverview = lazy(() => import("components/MainContentPages/DoctorOverview/DoctorOverview"));
const PractitionerInformation = lazy(() => import("components/MainContentPages/DoctorConfiguration/PractitionerInformation"));
const ChatNotification = lazy(() => import("components/utils/ChatNotification"));


export function RoutesComponent(props) {
  const { userData } = useAuth();
  const { locale } = useLocale();
  const { pageNotification, setPageNotification, chatNotification, setChatNotification } = usePageNotification();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [chatNotifications, setChatNotifications] = useState([]);
  const location = useLocation()
  const query = useUriQueryParams(location);

  // Scroll to top on redirect or refresh
  useEffect(() => {
    const scrollTop = setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);
    return () => {
      clearTimeout(scrollTop);
    }
  }, [location]);

  useEffect(() => {
    // Check if there is a new notification to be displayed
    if (pageNotification.isNotification) {
      setNotifications((prevNotifications) => {
        // Check if a similar notification already exists
        const existingNotificationIndex = prevNotifications.findIndex(
          notification => notification.message === pageNotification.message && notification.type === pageNotification.type
        );

        if (existingNotificationIndex !== -1) {
          // If it exists, update the count
          const updatedNotifications = [...prevNotifications];
          updatedNotifications[existingNotificationIndex] = {
            ...updatedNotifications[existingNotificationIndex],
            count: updatedNotifications[existingNotificationIndex].count + 1,
            closeAfterTime: pageNotification.closeAfterTime ?? 5000,
            shouldAutomaticallyClose: pageNotification.shouldAutomaticallyClose ?? true
          };
          return updatedNotifications;
        } else {
          // If it doesn't exist, add a new notification
          const newNotification = {
            id: Date.now(), // Generate a unique ID based on the current timestamp
            customNotificationHeading: pageNotification.customNotificationHeading,
            message: pageNotification.message,
            type: pageNotification.type,
            closeAfterTime: pageNotification.closeAfterTime ?? 5000,
            shouldAutomaticallyClose: pageNotification.shouldAutomaticallyClose ?? true,
            count: 1 // Initialize count
          };
          return [...prevNotifications, newNotification];
        }
      });
      setPageNotification({ isNotification: false, type: "", customNotificationHeading: "", message: "" });
    }
  }, [pageNotification, setPageNotification]);

  useEffect(() => {
    // Check if there is a new chat notification to be displayed
    if (chatNotification.isNotification) {
      setChatNotifications((prevNotifications) => {
        // Check if a similar notification already exists
        const existingNotificationIndex = prevNotifications.findIndex(
          notification => notification.message === chatNotification.message && notification.type === chatNotification.type
        );

        if (existingNotificationIndex !== -1) {
          // If it exists, update the count
          const updatedNotifications = [...prevNotifications];
          updatedNotifications[existingNotificationIndex] = {
            ...updatedNotifications[existingNotificationIndex],
            count: updatedNotifications[existingNotificationIndex].count + 1,
            closeAfterTime: chatNotification.closeAfterTime ?? 5000,
            shouldAutomaticallyClose: chatNotification.shouldAutomaticallyClose ?? true,
            conversationData: chatNotification.conversationData
          };
          return updatedNotifications;
        } else {
          // If it doesn't exist, add a new notification
          const newNotification = {
            id: Date.now(), // Generate a unique ID based on the current timestamp
            customNotificationHeading: chatNotification.customNotificationHeading,
            message: chatNotification.message,
            type: chatNotification.type,
            closeAfterTime: chatNotification.closeAfterTime ?? 5000,
            shouldAutomaticallyClose: chatNotification.shouldAutomaticallyClose ?? true,
            conversationData: chatNotification.conversationData,
            count: 1 // Initialize count
          };
          return [...prevNotifications, newNotification];
        }
      });

      setChatNotification({ isNotification: false, type: "", customNotificationHeading: "", message: "" });
    }
  }, [chatNotification, setChatNotification]);


  const removeNotification = (id) => {
    // Filter out the notification with the specified ID from the list of notifications
    setNotifications((prevNotifications) => prevNotifications.filter(notification => notification.id !== id));
    setChatNotifications((prevNotifications) => prevNotifications.filter(notification => notification.id !== id));
  };

  const getHomepageElement = () => {
    // Practitioner
    if (["Practitioner", "admin"].includes(userData.role)) {
      // Practitioner is active
      if (userData?.active) {
        return (
          <PageWrapper privatePage={true} activeOnly={true} requiredRole={["Practitioner"]}>
            <DoctorHomePage />
          </PageWrapper>
        )
      }

      // Practitioner is deactivated
      else {
        return (
          <PageWrapper privatePage={true} activeOnly={false} requiredRole={["Practitioner"]}>
            <PractitionerInformation practitionerRoleId={userData.practitionerRoleId} />
          </PageWrapper>
        )
      }
    }

    // Patient
    if (userData.role === "Patient") {
      return (
        <PageWrapper privatePage={true} activeOnly={true} requiredRole={["Patient"]}>
          <PatientHomePage />
        </PageWrapper>
      )
    }

    // No role
    return (
      <PageWrapper privatePage={false}>
        < LandingPage />
      </PageWrapper>
    )
  }

  return (
    <div id="route" className="main-content">
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* Ensure URL start with valid locale */}
          <Route path="/" element={<Navigate to={`/${locale}/`} replace />} />

          {/* 404 error component */}
          <Route index={true} element={
            <PageWrapper privatePage={false}>
              <NotFound />
            </PageWrapper>}
          />

          {/* If login has not been completed */}
          {/* {(user && (user.practitionerId || user.patientId) && (!userData || !userData.role)) &&
            <Route path={`/${locale}/`} element={<Navigate to={`/${locale}/login`} replace />} />
          } */}

          {/* Homepage*/}
          <Route path="/:locale/">
            <Route index={true} element={getHomepageElement()} />
            <Route path="*" element={
              <PageWrapper privatePage={false}>
                <NotFound />
              </PageWrapper>}
            />
          </Route>

          {/* Reset password */}
          <Route path="/:locale/reset-password" element={
            <PageWrapper privatePage={false} >
              <ResetPassword />
            </PageWrapper>
          } />

          {/* Reset email */}
          <Route path="/:locale/recover-email" element={
            <PageWrapper privatePage={false} >
              <RecoverEmail />
            </PageWrapper>
          } />

          {/* Reset password secret page */}
          <Route path="/:locale/reset-password/:id" element={
            <PageWrapper privatePage={false} >
              <ResetPasswordForm />
            </PageWrapper>
          } />

          {/* Confirm email page (public) */}
          <Route path="/:locale/confirm-email/:id" element={
            <PageWrapper privatePage={false} >
              <ConfirmEmailForm />
            </PageWrapper>
          } />

          {/* Login page (public) */}
          <Route path="/:locale/login" element={
            <PageWrapper privatePage={false} >
              <Login />
            </PageWrapper>
          } />

          {/* Organization Dashboard (Practitioner only) */}
          <Route path="/:locale/dashboard" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner", "help"]}>
              <DashboardPage />
            </PageWrapper>
          }
          />

          {/* Video Call page */}
          <Route path="/:locale/videoCall" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner", "Patient"]}>
              <VideoCallMenu
                selectedAppointmentId={query.get("selectedAppointmentId")}
              />
            </PageWrapper>
          }
          />

          {/* Chat page */}
          <Route path="/:locale/chat" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner", "Patient"]}>
              <Chat
                conversationId={query.get("conversationId")}
              />
            </PageWrapper>
          }
          />

          {/* eFolder Patient (Private) */}
          {userData.role === "Patient" &&
            <Route path="/:locale/patient/eFolder/:id" element={
              <PageWrapper
                privatePage={true}
                activeOnly={true}
                requiredRole={["Patient"]}>
                <PatientInformation viewType={"Patient"} />
              </PageWrapper>
            }
            />
          }

          {/* eFolder Practitioner (Private) */}
          {userData.role === "Practitioner" &&
            <Route path="/:locale/patient/eFolder/:id" element={
              <PageWrapper
                privatePage={true}
                activeOnly={true}
                requiredRole={["Practitioner", "help"]}>
                <PatientInformation viewType={"Practitioner"} />
              </PageWrapper>
            }
            />
          }

          {/* /createOrganization is a public route (does not need UserData) but still need you to be authenticated as a Practitioner (valid authToken + practitioner id) */}
          <Route path="/:locale/createOrganization" element={
            <PageWrapper
              privatePage={false}
            >
              <CreateOrganization />
            </PageWrapper>
          }
          />

          {/* Patient list page */}
          <Route path="/:locale/patientList" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner", "help"]}>
              <DoctorDisplayPatientResult />
            </PageWrapper>
          }
          />

          {/* Appointment sheduler page */}
          <Route path="/:locale/scheduler" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner", "help"]}>
              <FullScheduler />
            </PageWrapper>
          }
          />

          {/* Room view */}
          <Route path="/:locale/roomView/:roomId" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner", "help"]}>
              <RoomOverview />
            </PageWrapper>
          }
          />

          {/* Room list */}
          <Route path="/:locale/RoomList" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner", "help"]}>
              <RoomList />
            </PageWrapper>
          }
          />

          {/* Book appointment as a practitioner */}
          {userData.role === "Practitioner" &&
            <Route path="/:locale/appointment" element={
              <PageWrapper
                privatePage={true}
                activeOnly={true}
                requiredRole={["Practitioner", "help"]}
              >
                <NewAppointment />
              </PageWrapper>
            }
            />
          }

          {/* Book appointment as a Patient */}
          <Route path="/:locale/appointment" element={
            <PageWrapper
              privatePage={false}
            >
              <NewAppointmentPatient
                practitionerRoleId={query.get("practitionerRoleId")}
              />
            </PageWrapper>
          }
          />

          {/* Appointment details */}
          <Route path="/:locale/appointment/:patientId/:appointmentId" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Patient", "Practitioner", "help"]}>
              <AppointmentDetails />
            </PageWrapper>
          }
          />

          {/* New doctor registration */}
          <Route path="/:locale/newDoctor" element={
            <PageWrapper
              privatePage={false}
            >
              <NewDoctorRegistration />
            </PageWrapper>
          }
          />

          {/* Patient Homepage */}
          <Route path="/:locale/documentManagement" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Patient"]}>
              <DocumentManagementContainer
                userType="Patient"
                view="fullPage"
              />
            </PageWrapper>
          }
          />

          {/* Patient doctor overview */}
          <Route path="/:locale/doctorOverview/:id" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Patient"]}>
              <DoctorOverview />
            </PageWrapper>
          }
          />

          <Route path="/:locale/patientSideTimeline" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Patient"]}>
              <PatientSideTimeline />
            </PageWrapper>
          }
          />

          {/* Avatar dropdown menu routing */}
          <Route path="/:locale/user-profile" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Patient", "Practitioner", "help"]}>
              <UserProfile />
            </PageWrapper>
          }
          />

          <Route path="/:locale/doctor-configuration" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
              requiredRole={["Practitioner"]}>
              <DoctorConfiguration
                category={query.get("category")}
                practitionerRoleId={query.get("practitionerRoleId")} //If category = managePractitionerRoles
              />
            </PageWrapper>
          } />

          <Route path="/:locale/notifications" element={
            <PageWrapper
              privatePage={true}
              activeOnly={true}
            >
              <NotificationCenter />
            </PageWrapper>
          } />

          {/* Public pages, Landing page */}
          <Route path="/:locale/" element={
            <PageWrapper
              privatePage={false}
            >
              <LandingPage />
            </PageWrapper>
          } />
          <Route path="/:locale/contact" element={
            <PageWrapper
              privatePage={false}
            >
              <ContactPage />
            </PageWrapper>
          } />
          <Route path="/:locale/about" element={
            <PageWrapper
              privatePage={false}
            >
              <About />
            </PageWrapper>
          } />
          <Route path="/:locale/terms-conditions" element={
            <PageWrapper
              privatePage={false}
            >
              <TermsConditions />
            </PageWrapper>
          } />
          <Route path="/:locale/privacy-policy" element={
            <PageWrapper
              privatePage={false}
            >
              <PrivacyPolicy />
            </PageWrapper>
          } />
          <Route path="/:locale/learn-more-doctor" element={
            <PageWrapper
              privatePage={false}
            >
              <LearnMoreDoctor />
            </PageWrapper>
          } />
          <Route path="/:locale/for-patients" element={
            <PageWrapper
              privatePage={false}
            >
              <ForPatients />
            </PageWrapper>
          } />
          <Route path="/:locale/help" element={
            <PageWrapper privatePage={false} >
              <Help />
            </PageWrapper>
          } />
        </Routes>

        {notifications.length > 0 && (
          <div className="tw-fixed tw-flex tw-flex-col tw-items-end tw-w-full tw-top-3 tw-right-3 tw-space-y-2 tw-z-1000" style={{ pointerEvents: 'none' }}>
            {notifications.map((notification) => (
              <NotificationToolbar
                key={notification.id}
                customNotificationHeading={notification.customNotificationHeading}
                message={notification.message}
                type={notification.type}
                count={notification.count} // Pass count to the NotificationToolbar
                handleClose={() => removeNotification(notification.id)}
                closeAfterTime={notification.closeAfterTime}
                shouldAutomaticallyClose={notification.shouldAutomaticallyClose}
                containerStyle={{ pointerEvents: 'auto' }}
              />
            ))}
          </div>
        )}

        {chatNotifications.length > 0 &&
          <>
            {chatNotifications.map((notification) => (
              <ChatNotification
                key={notification.id}
                shouldAutomaticallyClose={notification.shouldAutomaticallyClose}
                conversationData={notification.conversationData}
                message={notification.message}
                type={notification.type}
                closeAfterTime={notification.closeAfterTime}
                handleClose={() => removeNotification(notification.id)}
                handleReply={() => {
                  // Open chat with the conversationData
                  removeNotification(notification.id)
                  navigate(`/${locale}/chat?conversationId=${notification.conversationData.conversationId}`);
                }}
              />
            ))}
          </>
        }
      </Suspense>
    </div>
  );
}

export default RoutesComponent;
