/* Copyright © FINANCE SECURITY GmbH - All rights reserved. */

import { useState, useEffect, useCallback } from 'react'
import { useLocale } from "../context/locale";

import { localeArray } from "../utils/localization";

// Given the component name, load and provide translation
function useLoadTranslation(componentName) {
    const { locale } = useLocale()

    const [translation, setTranslation] = useState({})

    const loadTranslation = useCallback((locale, component) => {
        if (localeArray.includes(locale)) {
            return require(`../data/locale/${locale}/${component}.json`);
        }
        return require(`../data/locale/en-us/${component}.json`);
    }, [])

    useEffect(() => {
        let translationFile = loadTranslation(locale, componentName);
        setTranslation(translationFile);
    }, [locale, componentName, loadTranslation])

    return translation
}

export default useLoadTranslation
