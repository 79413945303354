import { useState, useEffect, useMemo } from "react"
import io from "socket.io-client"
import dayjs from "dayjs";

import { ChatContext } from "../context/chat";
import { usePageNotification } from "context/pageNotification";
import { useAuth } from "../context/auth";

import { getChatIsProcessingAi } from 'api/restApiRequests/user/getChatIsProcessingAi'
import { getChatParticipants } from 'api/restApiRequests/user/getChatParticipants'
import { getUserChatMessages } from 'api/restApiRequests/user/getUserChatMessages'

import useLoadTranslation from "hooks/useLoadTranslation";

function ChatProvider(props) {
    const translation = useLoadTranslation("Chat");
    const { user, userData } = useAuth()
    const { setPageNotification, setChatNotification } = usePageNotification()

    const [participantsData, setParticipantsData] = useState([])
    const [conversationsData, setConversationsData] = useState([])
    const [hasSeenAiProcessingMessage, setHasSeenAiProcessingMessage] = useState(false)
    const [isProcessingAIMessage, setIsProcessingAIMessage] = useState(false)
    const [newConversationId, setNewConversationId] = useState(null)
    const [currentChatOpen, setCurrentChatOpen] = useState(null) //Used not to send notification if user is in chat

    const [hasJoinedSocketRooms, setHasJoinedSocketRooms] = useState(false)

    const getConversationProfilePicture = (conversation) => {
        if (conversation?.profilePicture) {
            return conversation.profilePicture
        }

        try {
            // Get all profile picture from first 3 participants 
            let pictures = []
            for (let participant of participantsData) {
                if (conversation?.participants?.map((p) => p.id)
                    .includes(participant.id) && participant.profilePicture) {
                    pictures.push(participant.profilePicture)
                }

                if (pictures?.length === 3) {
                    break
                }
            }

            // TODO: merge the profile pictures together to make an image with 3 profile pictures for a group conversation
            switch (pictures?.length) {
                case 0:
                    return null
                case 1:
                    return pictures[0]
                case 2:
                    // todo
                    return pictures[0]
                case 3:
                    // todo
                    return pictures[0]
                default:
                    return null
            }
        }
        catch (e) {
            return null
        }


    }

    // #######################################
    // Socket.io
    // #######################################

    const socket = useMemo(() => {
        if (user.patientId || userData.practitionerRoleId) {
            return io.connect(`${process.env.REACT_APP_DOCAPPY_WEBSOCKET_URI}`, {
                withCredentials: true,
                path: process.env.REACT_APP_DOCAPPY_SOCKETPATH,
                transports: ['websocket'],
            });
        }
        return null
    }, [user, userData]);

    useEffect(() => {
        // Return a cleanup function to disconnect the session when the component unmounts
        return () => {
            socket?.emit('disconnect_event');
        };
        // eslint-disable-next-line
    }, [])

    // Join room for own ID and each participant ID
    // Note: if new participant is added (appointment with new doctor/patient for example), user has to refresh the page to see new chat
    useEffect(() => {
        if (!socket) return;

        // Wait until socket is connected
        socket.on("connect", () => {
            if (!hasJoinedSocketRooms
                // && (userData.practitionerRoleId || user.patientId)
            ) {
                socket.emit("join_rooms", { authorId: userData.practitionerRoleId ?? user.patientId });
                setHasJoinedSocketRooms(true);
            }
        });

        // Clean up on disconnect
        return () => {
            socket.off("connect");
        };

        // eslint-disable-next-line
    }, [hasJoinedSocketRooms, socket])

    // Handle socket.io connections: Receiving new messages
    useEffect(() => {
        if (!socket) return;

        const handleMessage = (data) => {
            // Handle AI-specific logic
            if (data?.senderId === "AI") {
                setIsProcessingAIMessage(false);
            }

            // Update conversationData state
            setConversationsData((prevConversationsData) => {
                const updatedConversationsData = prevConversationsData.map((conversation) => {
                    if (conversation.id === data.conversationId) {
                        const newConversation = { ...conversation };
                        newConversation.messages.push(data);
                        newConversation.messagesFetched += 1;
                        return newConversation;
                    }
                    return conversation;
                });

                return updatedConversationsData;
            });

            // Get conversation displayName and profile picture from conversationData
            const conversationData = conversationsData.find(conversation => conversation.id === data.conversationId)
            // Get from participantsData the displayName of the sender
            const senderDisplayName = participantsData.find(participant => participant.id === data.senderId)?.displayName

            // Create notification for new message if not sent by current user
            if (data?.senderId !== userData.practitionerRoleId
                && data?.senderId !== user.patientId
                && currentChatOpen !== data.conversationId //Don't display for currently opened conversation
            ) {
                setChatNotification({
                    isNotification: true,
                    type: "chatMessage",
                    message: data.content,
                    conversationData: {
                        conversationId: data.conversationId,
                        displayName: conversationData.displayName,
                        senderDisplayName: senderDisplayName,
                        profilePicture: conversationData.profilePicture
                    },
                    shouldAutomaticallyClose: true,
                    closeAfterTime: 5000
                })
            }

        };

        // Attach the event listener
        socket.on("receive_message", handleMessage);

        // Clean up the event listener on unmount or when socket changes
        return () => {
            socket.off("receive_message", handleMessage);
        };
        // eslint-disable-next-line 
    }, [socket, conversationsData, participantsData]);


    // Handle socket.io connections: Error sending message
    useEffect(() => {
        if (!socket) return;

        const handleError = (data) => {
            if (data?.data?.receiverId === "AI") {
                setIsProcessingAIMessage(false);
            }

            // Handle different error reasons
            switch (data.errorReason) {
                case "notAuthorized":
                    setPageNotification({
                        isNotification: true,
                        type: "error",
                        message: translation.notAuthorized,
                    });
                    break;
                default:
                    setPageNotification({
                        isNotification: true,
                        type: "error",
                        message: translation.errorSending,
                    });
            }
        };

        // Attach the event listener
        socket.on("send_message_error", handleError);

        // Clean up the event listener on unmount or when socket changes
        return () => {
            socket.off("send_message_error", handleError);
        };
        // eslint-disable-next-line
    }, [socket, translation]);


    // Handle socket.io connection: Error joining chat room
    useEffect(() => {
        if (!socket || !(userData.practitionerRoleId || user.patientId)) return;

        const handleJoinRoomError = (data) => {
            setPageNotification({
                isNotification: true,
                type: "error",
                message: translation.errorRefreshing,
            });
        };

        // Attach the event listener
        socket.on("join_rooms_error", handleJoinRoomError);

        // Clean up the event listener on unmount or when socket changes
        return () => {
            socket.off("join_rooms_error", handleJoinRoomError);
        };
        // eslint-disable-next-line
    }, [socket, user, userData, translation]);

    // Handle socket.io connection: Joining a new conversation room
    useEffect(() => {
        if (!socket) return;

        const handleNewConversation = (data) => {
            // Update conversationData state
            setConversationsData((prevConversationsData) => {
                // Search in participants those matching data.participants.id and add their displayName to conversation displayName
                const conversationDisplayName = participantsData
                    .filter(p1 => data.participants.map(p2 => p2.id).includes(p1.id))
                    .map((p) => p.displayName)
                    .join(", ")

                data.displayName = conversationDisplayName
                data.profilePicture = getConversationProfilePicture(data)

                // Add back the type of each participant in the conversation
                let participants = []
                for (let participant of data.participants) {
                    let type

                    const data = participantsData
                        .find((p) => p.id === participant.id)

                    if (data) {
                        type = data.type
                    }
                    else {
                        if (userData?.practitionerRoleId) {
                            type = "practitionerRole"
                        }
                        else {
                            type = "patient"
                        }
                    }

                    participants.push({
                        id: participant.id,
                        type: type
                    })
                }

                data.participants = participants

                const updatedConversationsData = [...prevConversationsData, data];
                return updatedConversationsData;
            });

            const senderId = data?.messages[0]?.senderId
            // If the user is the sender, set the new conversation id to automatically redirect them to the page with the new message
            if (senderId === userData.practitionerRoleId || senderId === user.patientId) {
                setNewConversationId(data.id);
            }


            socket.emit("join_room", { roomId: data.id, authorId: userData.practitionerRoleId ?? user.patientId });
        };

        // Attach the event listener
        socket.on("new_conversation", handleNewConversation);

        // Clean up the event listener on unmount or when socket changes
        return () => {
            socket.off("new_conversation", handleNewConversation);
        };
        // eslint-disable-next-line
    }, [socket, participantsData, getConversationProfilePicture]);



    // #######################################
    // Other useEffects
    // #######################################

    // Get list of all participants (chats existing and potential new chats)
    useEffect(() => {
        if (userData && user) {
            getChatParticipantsFunction({
                authorId: userData.practitionerRoleId ?? user.patientId
            })
        }
        // eslint-disable-next-line
    }, [userData, user])

    // Check on loadup if is currently processing an AI message (prevent from sending duplicates)
    useEffect(() => {
        if (!userData?.practitionerRoleId && !user?.patientId) {
            return
        }

        getCheckChatIsProcessingAi({ authorId: userData.practitionerRoleId ?? user.patientId })

        // eslint-disable-next-line
    }, [userData, user])


    // #######################################
    // REST API Calls
    // #######################################

    const handleFetchMessages = async ({ conversationId, offset }) => {
        let errorReason
        try {
            const d = await getUserChatMessages({
                authorId: userData.practitionerRoleId ?? user.patientId,
                conversationId,
                offset
            })

            if (!d?.success) {
                errorReason = d?.errorReason ?? "getFail"
                throw new Error(d?.error ?? "Failed to get chat messages")
            }

            setConversationsData((prevConversationsData) => {
                const updatedConversationsData = prevConversationsData.map(conversation => {
                    if (conversation.id === conversationId) {
                        const newConversation = { ...conversation }

                        newConversation.profilePicture = getConversationProfilePicture(newConversation)

                        newConversation.messages = [...newConversation.messages, ...d.data]
                            .sort((a, b) => dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? -1 : 1)
                            // Filter for id unicity
                            .filter((message, index, self) =>
                                index === self.findIndex((t) => (
                                    t.id === message.id
                                ))
                            )

                        newConversation.messagesFetched = newConversation.messagesFetched + d.data.length
                        return newConversation
                    }
                    return conversation
                })

                return updatedConversationsData
            })


            // // Get new participant data for selected participant 
            // let updatedParticipant = participantsData.find(participant => participant.id === participantId)
            // updatedParticipant.messages = [...updatedParticipant.messages, ...d.data].sort((a, b) => dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? -1 : 1);
            // updatedParticipant.messagesFetched = updatedParticipant.messagesFetched + d.data.length

            // // Update participantsData
            // setParticipantsData((prevParticipantsData) => {
            //     const updatedParticipantsData = prevParticipantsData.map(participant => {
            //         if (participant.id === participantId) {
            //             return updatedParticipant;
            //         }
            //         return participant;
            //     });

            //     return updatedParticipantsData;
            // })
        }
        catch (e) {
            setPageNotification({
                isNotification: true,
                type: "error",
                message: translation.errorGettingMessage
            })
        }
    }

    const getChatParticipantsFunction = async ({ authorId }) => {
        let errorReason

        if (!authorId) {
            return
        }

        try {
            const d = await getChatParticipants({ authorId })

            if (!d?.success) {
                errorReason = d?.errorReason ?? "getFail"
                throw new Error(d?.error ?? "Failed to get chat participants")
            }

            setParticipantsData(d.data?.participants.map(
                (chat) => {
                    return ({
                        ...chat,
                        displayName: translation[chat.displayName] ?? chat.displayName
                    })
                }
            ));

            // Add a conversation name if none has been attributed
            const conversations = d.data?.conversations.map(conversation => {
                conversation.profilePicture = getConversationProfilePicture(conversation)

                if (!conversation.displayName) {
                    // Find in d.data?.participants the participants of the conversation and add their "displayName" to conversation displayName
                    const participants = conversation.participants.map(participantId => {
                        return d.data?.participants.find(participant => {
                            return (participant.id === participantId.id)
                        }
                        )
                    }).filter(participant => participant)

                    conversation.displayName = participants.map(participant => participant.displayName).join(", ")
                }
                return conversation
            })

            setConversationsData(conversations)
        }
        catch (e) {
            setPageNotification({
                isNotification: true,
                type: "error",
                message: translation.errorFetching
            })
        }
    }

    // #######################################
    // Handle functions
    // #######################################

    const retryAiMessage = () => {
        socket?.emit("retry_ai_message", { emitterId: userData.practitionerRoleId ?? user.patientId })
    }

    const handleMarkAsSeen = async ({ receiverId, conversationId }) => {
        if (!receiverId || !conversationId || conversationId === "AI") {
            return
        }

        socket?.emit("seen_message", { receiverId, conversationId })

        // Refresh conversationsData to show no new message based on conversationId

        // TODO verify it works
        const updatedConversationsData = conversationsData.map(conversation => {
            if (conversation.id === conversationId) {
                const newConversation = { ...conversation }
                newConversation.messagesUnread = 0
                return newConversation
            }
            return conversation
        })

        setConversationsData(updatedConversationsData)
    }

    const handleSendMessage = async ({ authorId, participantsResourceIds, message, conversationId = null, isAi = false }) => {
        let errorReason

        try {
            if (!message?.length) {
                errorReason = "emptyMessage"
                throw new Error("Message content is empty")
            }

            const messageData = {
                content: message,
                emitterId: authorId,
                conversationId: conversationId,
                participantsResourceIds,
                //Must match what the user on the other side will receive (what sender is to them)
                isAi,
            }

            if (conversationId && conversationId !== "AI") {
                await socket?.emit("send_message", messageData);
            }
            else {
                await socket?.emit("send_message_new_conversation", messageData);
            }

            if (isAi) {
                setIsProcessingAIMessage(true)

                // First AI message sent will display you a notification to be patient
                if (!hasSeenAiProcessingMessage) {
                    setPageNotification({
                        isNotification: true,
                        type: "ok",
                        message: translation.messageProcessingNotification
                    })

                    setHasSeenAiProcessingMessage(true)
                }
            }
        }
        catch (e) {
            switch (errorReason) {
                case "emptyMessage":
                    setPageNotification({
                        isNotification: true,
                        type: "error",
                        message: translation.emptyMessages
                    })
                    break
                default:
                    setPageNotification({
                        isNotification: true,
                        type: "error",
                        message: translation.errorSending
                    })
            }
        }
    }

    const getCheckChatIsProcessingAi = async ({ authorId }) => {
        try {
            const d = await getChatIsProcessingAi({ authorId: userData.practitionerRoleId ?? user.patientId })

            if (!d?.success) {
                throw new Error(d?.error ?? "Failed to get chat participants")
            }

            if (d.data.hasOngoingRequest) {
                setIsProcessingAIMessage(true)
            }
        }
        catch (e) {
        }
    }

    // #######################################
    // Return provider
    // #######################################

    return (
        <ChatContext.Provider
            value={{
                participantsData,
                conversationsData,
                isProcessingAIMessage,
                newConversationId,
                handleSendMessage,
                handleMarkAsSeen,
                retryAiMessage,
                handleFetchMessages,
                setCurrentChatOpen
            }}>
            {props.children}
        </ChatContext.Provider>
    )
}

export default ChatProvider