// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import React from 'react'
import PropTypes from 'prop-types'

import { buildHandleEnterKeyPress } from '../../../../utils/utilsFunctions'

export function Button(props) {

    const handleButtonClasses = () => {
        let classes = []
        if (props.active) {
            classes.push("active:tw-bg-indigo-800")
        }
        if (props.className.length > 0) {
            classes.push(props.className)
        }
        if (props.disabled) {
            classes.push("tw-bg-gray-400 hover:tw-bg-gray-400 hover:tw-border-gray-400 tw-border tw-border-gray-400 !tw-cursor-not-allowed	")
        }
        if (props.isCancel) {
            classes.push("!tw-bg-gray-300 tw-border !tw-border-gray-300 !tw-text-gray-900 hover:!tw-bg-gray-300")
        }
        if (props.isDelete) {
            classes.push("tw-text-white tw-bg-red-600 tw-border-red-600 hover:!tw-border-red-800 hover:tw-bg-red-800 tw-border")
        }
        if (props.isSecondary) {
            classes.push("text-color-gray tw-bg-gray-50 hover:tw-bg-gray-200 tw-border tw-border-gray-300 hover:tw-text-gray-900")
        }

        // "Normal" button
        if (!props.isCancel && !props.disabled && !props.isSecondary && !props.isDelete) {
            classes.push("tw-text-white tw-bg-blue-700 tw-border tw-border-blue-700 hover:tw-bg-indigo-800 hover:tw-border-indigo-800")
        }

        //What we need for the button in all cases
        classes.push("focus:tw-outline-none focus:tw-ring tw-h-fit tw-transition tw-duration-300 tw-ease-in-out tw-cursor-pointer hover:tw-shadow tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-text-center")
        return (classes.join(" "))
    }

    const classes = handleButtonClasses()

    //Can not be clicked
    if (props.disabled) {
        return (
            <div
                data-button={props.dataButton}
                className={classes}
                aria-label={props.ariaLabel}
                tabIndex={0}
                role="button"
            >
                {props.text}
                {props.children}
            </div>
        )
    }

    if (props.isCancel) {
        return (
            <div
                data-button={props.dataButton}
                className={`tw-h-fit tw-transition tw-duration-300 tw-ease-in-out tw-cursor-pointer text-color-gray tw-bg-gray-50 hover:tw-bg-gray-200 hover:tw-shadow tw-rounded-lg tw-border tw-border-gray-300 tw-text-sm tw-px-5 tw-py-2.5 hover:tw-text-gray-900 tw-text-center ${classes}`}
                onClick={props.handleClick}
                aria-label={props.ariaLabel}
                tabIndex={0}
                onKeyPress={buildHandleEnterKeyPress(props.handleClick)}
            >
                {props.text}
                {props.children}
            </div>
        )
    }

    //Can be clicked
    return (
        <div
            data-button={props.dataButton}
            className={classes}
            onClick={props.handleClick}
            aria-label={props.ariaLabel}
            tabIndex={0}
            onKeyPress={buildHandleEnterKeyPress(props.handleClick)}
        >
            {props.text}
            {props.children}
        </div>
    )
}

Button.propTypes = {
    disabled: PropTypes.bool,
    isCancel: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isDelete: PropTypes.bool,
    active: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
    dataButton: PropTypes.string,
    handleClick: PropTypes.func
}

Button.defaultProps = {
    disabled: false,
    isCancel: false,
    isSecondary: false,
    isDelete: false,
    active: false,
    text: "",
    className: "",
    ariaLabel: "",
    dataButton: "",
    handleClick: () => { }
}

export default Button
