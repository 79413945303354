import React, { useState, useEffect } from 'react'

import { AppointmentSearchParamsContext } from "context/appointmentSearchParams"

import { useLocale } from "context/locale";

function AppointmentSearchParamsContextProvider(props) {
    const { locale } = useLocale()

    // Patient appointment booking search filters
    const [searchSettings, setSearchSettings] = useState({
        textInput: "",
        insuranceType: "any",
        distance: 0,
        language: locale,
        nbResults: 100
    })

    // On change locale, we change the "default" settings
    useEffect(() => {
        setSearchSettings((old) => { return ({ ...old, language: locale }) })
    }, [locale])

    const resetSearchSettings = () => {
        setSearchSettings({
            textInput: "",
            insuranceType: "any",
            distance: 0,
            language: locale,
            nbResults: 100
        })
    }


    return (
        <AppointmentSearchParamsContext.Provider
            value={{
                searchSettings: searchSettings,
                setSearchSettings: setSearchSettings,
                resetSearchSettings: resetSearchSettings
            }}
        >
            {props.children}
        </AppointmentSearchParamsContext.Provider>
    )
}

export default AppointmentSearchParamsContextProvider