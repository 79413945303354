// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: {
// token: String!,
// authorId: ID (depends on "type" requested),
// id: docappy id,
// type: String! ("Docappy", ??),
// practitionerRoleId: ID!
// }


export const getPreferences = async (data) => {
    return axiosWrapper({
        data,
        method: "get",
        url: "preferences"
    })
}