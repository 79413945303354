// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import { useState, useEffect } from 'react'

import { getUserUserdata } from "../../api/restApiRequests/user/getUserUserdata";

function useGetUserData({ user, roleData, loading, logout = () => { } }) {
  const [userData, setUserData] = useState({ loading: true })

  // ########################
  // useEffect
  // ########################

  //When get user token, get userData
  useEffect(() => {
    async function getUserData() {
      if (loading) {
        return
      }

      if (!user || (!user.patientId && !user.practitionerId)) {
        if (user.loading) {
          setUserData({ loading: true })
        }
        else {
          setUserData({ loading: false })
        }
        return
      }

      // User has data in "user" object but no role yet: should reset userData to "loading" state until user selects a role
      if (!roleData || !roleData.role) {
        setUserData({ loading: true })
        return
      }

      try {
        const d = await getUserUserdata({
          type: roleData.role, // "Patient" || "Practitioner"
          authorId: user.id,  // docappyId!
          requiredId: roleData.role === "Patient" ? null : roleData.practitionerRoleId
        })

        if (!d?.success) {
          throw new Error(d ? d.error : "Error graphql query")
        }

        setUserData({ ...d.data, loading: false })
      }
      catch (e) {
        setUserData({ loading: false })
      }
    }

    getUserData()
    // eslint-disable-next-line
  }, [user, roleData, logout])

  return userData

}

export default useGetUserData