// Copyright © FINANCE SECURITY GmbH - All rights reserved.

export const month = ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember']
export const days = ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag']

export default function date () {
    const days = ['So','Mo','Di','Mi','Do','Fr','Sa']

    return [month,days]
}

