// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import { axiosWrapper } from 'api/axiosUtils'

// data : {
// authorId: patientId!,
// language: String!, 
// }

export const postLanguage = async (data) => {
    return axiosWrapper({
        data,
        method: "post",
        url: "patient/language"
    })
}