// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: {
//     authorId: patientId! || practitionerRoleId!
// }

export const getChatIsProcessingAi = async (data) => {
    return axiosWrapper({
        data,
        method: "get",
        url: "user/chat/ai/checkIfProcessing"
    })
}