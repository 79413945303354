// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import dayjs from "dayjs"

import { localeArray } from "./localization"

var objectSupport = require("dayjs/plugin/objectSupport");
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(objectSupport)
dayjs.extend(customParseFormat)

const dayjsLocales = {
    de: () => import("dayjs/locale/de"),
    en: () => import("dayjs/locale/en"),
    fr: () => import("dayjs/locale/fr"),
    hr: () => import("dayjs/locale/hr"),
}

export const loadDayjsLocale = (language) => {
    if (language && language.length > 0) {
        const dayjsLanguage = language.substring(0, 2)
        if (dayjsLocales[dayjsLanguage]) {
            dayjsLocales[dayjsLanguage]()
                .then(() => dayjs.locale(dayjsLanguage))
        }
    }
}


export const orderDates = (arr) => {
    let arrCopy = arr
    return (arrCopy.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1)))
}

//Return a sorted array of objects based on the property name passed as arguments.
export const sortArrayByPropertyDate = (arr, property) => {
    let arrCopy = arr
    arrCopy.sort((a, b) => (dayjs(a[property])).isAfter(dayjs(b[property])) ? 1 : -1)
    return arrCopy
}

//return true if periods of form {[startPropertyName]: dayjs date, [endPropertyName]: dayjs date} are overlapping
export function arePeriodsOverlapping(period1, period2, startPropertyName, endPropertyName) {
    let startTime1 = dayjs(period1[startPropertyName])
    let startTime2 = dayjs(period2[startPropertyName])
    let endTime1 = dayjs(period1[endPropertyName])
    let endTime2 = dayjs(period2[endPropertyName])

    let overlapping1 = startTime1.isBefore(startTime2) && (startTime2.isBefore(endTime1) || startTime2.isSame(endTime1))
    let overlapping2 = startTime2.isBefore(startTime1) && (startTime1.isBefore(endTime2) || startTime1.isSame(endTime2))
    let overlapping3 = startTime1.isSame(startTime2) && endTime1.isSame(endTime2)

    return (overlapping1 || overlapping2 || overlapping3)
}

//Take a dayjs object and a number of minute (int), return a dayjs object with the time rounded upward as a multiple of the minute argument
//Ex :  if time is 10:23 and minute 5 -> 10:25  || if time is 08:12 and minute 30 -> 08:30
export function roundTimeMinuteToNearestGivenUpward(time, minute) {
    //check type of minute
    if (!minute || minute < 0 || minute > 60) {
        return time
    }

    const remainder = minute - (time.minute() % minute)
    const newTime = time.add(remainder, "minutes")

    return newTime
}


export const loadDateLocalization = (locale) => {
    if (localeArray.includes(locale)) {
        return (require(`../data/date-localization/date_${locale}`))
    }
    return (require(`../data/date-localization/date_en-us`))
}

// Give list of days of the week (7 days) from a given day (as dayjs() objects), 
// starting with the day of the week defined in the preferences (sunday = 0, monday=1)
export const getDaysOfWeekDayjs = (day, startingDayOfWeek = 0) => {
    let daysOfWeek = []
    const dayOfSelectedDay = dayjs(day).day()
    // Get first day of the week
    if (dayOfSelectedDay >= startingDayOfWeek) {
        daysOfWeek.push(dayjs(day).day(startingDayOfWeek))
    }
    else {
        daysOfWeek.push(dayjs(day).subtract(7 + dayOfSelectedDay - startingDayOfWeek, "day"))
    }

    for (let i = 1; i < 7; i++) {
        daysOfWeek.push(dayjs(daysOfWeek[0]).add(i, "day"))
    }

    return daysOfWeek
}

export const getStartingDayOfWeek = (preferences) => {
    try {
        // Default value (no preferences)
        // By default, if navigator is on american english, week start with "Sunday", else it start with "Monday"
        // let startingDayOfWeek = navigator.language === "en-US" ? 0 : 1 //Not detected correctly from Chrome, deactivated for now
        let startingDayOfWeek = 1 // Default to monday

        // Is Practitioner
        if (preferences && preferences.practitionerPreferences && preferences.practitionerPreferences.startingDayOfWeek !== null && preferences.practitionerPreferences.startingDayOfWeek !== undefined) {
            startingDayOfWeek = preferences.practitionerPreferences.startingDayOfWeek
        }
        // Is Patient
        else if (preferences && preferences.patientPreferences && preferences.patientPreferences.startingDayOfWeek !== null && preferences.patientPreferences.startingDayOfWeek !== undefined) {
            startingDayOfWeek = preferences.patientPreferences.startingDayOfWeek
        }

        return startingDayOfWeek
    }
    catch (e) {
        // return navigator.language === "en-US" ? 0 : 1 //Not detected correctly from Chrome, deactivated for now
        return 1
    }
}