import { useState, useEffect } from "react"

import { getNotification } from "../api/restApiRequests/getNotification";

import { NotificationsContext } from "../context/notifications";
import { usePageNotification } from '../context/pageNotification';
import { useAuth } from "../context/auth";

import { postUpdateNotificationSeen } from "api/restApiRequests/user/postUpdateNotificationSeen"

import useLoadTranslation from "../hooks/useLoadTranslation";
import useEventLogging from "../hooks/useEventLogging";

function NotificationProvider(props) {
    const { user, userData } = useAuth()
    const { setPageNotification } = usePageNotification()
    const { eventLogging } = useEventLogging()
    const translation = useLoadTranslation("NotificationProvider");

    const [notificationData, setNotificationData] = useState({
        notificationsSeen: [],
        notificationsNotSeen: [],
        systemNotifications: [],
        messagesNotSeen: 0,
    })

    const getAsyncNotification = async () => {
        if (!user?.id || !userData?.id) {
            return
        }

        let errorReason

        try {
            let d = await getNotification({
                docappyId: user.id,
                practitionerRoleId: userData.practitionerRoleId,
            })

            if (!d || !d.success) {
                errorReason = d?.errorReason ?? "queryFailed"
                throw new Error(d ? d.error : "Error get Notification")
            }

            setNotificationData(d.data)
        }
        catch (e) {
            if (errorReason = "invalidCredentials") {
                setPageNotification({ isNotification: true, type: 'info', message: `${translation.loggedOut}`, shouldAutomaticallyClose: false })
            }
            else {
                setPageNotification({ isNotification: true, type: 'error', message: `${translation.errorNotification}` })
            }
        }
    }

    useEffect(() => {
        getAsyncNotification()
        // eslint-disable-next-line
    }, [userData, user, setPageNotification, translation])

    // #######################################
    // Graphql operations
    // #######################################

    const updateNotificationSeenMutation = async (notificationId, showSuccessNotification = true) => {
        try {
            const d = await postUpdateNotificationSeen({
                type: "one",
                notificationId,
                authorId: userData?.role === "Practitioner" ? userData.practitionerRoleId : user.patientId,
                ids: {
                    patientId: user.patientId,
                    docappyId: user.id,
                    practitionerId: user.practitionerId,
                    practitionerRoleId: userData.practitionerRoleId
                }
            })

            if (!d || !d.success) {
                throw new Error(d ? d.error : "Error graphql query")
            }

            if (showSuccessNotification) {
                setPageNotification({
                    isNotification: true,
                    type: 'ok',
                    message: `${translation.notificationMarkedAsSeen}`
                })
            }

            //OnCompleted
            getAsyncNotification()
            return ({ success: true, data: d.data })
        }
        catch (e) {
            //OnError
            eventLogging(200091, false, e)
            return ({ success: false })
        }
    }

    // Update multiple notification to "seen" for a user
    const updateMultipleNotificationsSeenMutation = async (notificationIds) => {
        if (!notificationIds || notificationIds.length === 0) {
            return
        }

        try {
            await Promise.all(notificationIds.map(async (notificationId) => {
                const dSingleNotification = await updateNotificationSeenMutation(notificationId, false)

                if (!dSingleNotification || !dSingleNotification.success) {
                    throw new Error(dSingleNotification ? dSingleNotification.error : "Error graphql query")
                }
            }))

            setPageNotification({
                isNotification: true,
                type: 'ok',
                message: `${translation.notificationsMarkedAsSeen}`
            })
            getAsyncNotification()
        }
        catch (e) {
            return ({ success: false, error: e })
        }

    }

    // Update all notifications to "seen" for a user. Does not include "organization" notifications for a practitioner
    const updateAllNotificationSeenMutation = async () => {
        try {
            const d = await postUpdateNotificationSeen({
                type: "all",
                ids: {
                    patientId: user.patientId,
                    docappyId: user.id,
                    practitionerId: user.practitionerId,
                    practitionerRoleId: userData.practitionerRoleId
                }
            })

            if (!d || !d.success) {
                throw new Error(d ? d.error : "Error graphql query")
            }

            //OnCompleted
            getAsyncNotification()
            return ({ success: true, data: d.data })
        }
        catch (e) {
            //OnError
            eventLogging(200092, false, e)
            return ({ success: false })
        }
    }

    return (
        <NotificationsContext.Provider
            value={{
                notifications: notificationData,
                updateNotificationSeenMutation,
                updateMultipleNotificationsSeenMutation,
                updateAllNotificationSeenMutation,
                refreshNotifications: getAsyncNotification,
            }}>
            {props.children}
        </NotificationsContext.Provider>
    )
}

export default NotificationProvider