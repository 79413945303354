import React, { useState, useMemo, useCallback, useEffect } from 'react'

import { UserPreferencesContext } from 'context/userPreferences'
import { useAuth } from 'context/auth'
import { useLocale } from 'context/locale'

import { useDisplaySpecialScreenContext } from 'context/displaySpecialScreen';
import { usePageNotification } from 'context/pageNotification';

import { getPreferences } from "api/restApiRequests/user/getPreferences";
import { setPreferences } from "api/restApiRequests/user/setPreferences";

function UserPreferencesProvider(props) {
    const { displaySpecialScreen, setDisplaySpecialScreen } = useDisplaySpecialScreenContext()
    const { setPageNotification } = usePageNotification()
    const { userData, user } = useAuth()
    const { locale } = useLocale()

    const translation = useMemo(() => {
        return require(`data/locale/${locale}/App.json`);
    }, [locale])

    const [userPreferences, setUserPreferences] = useState(null)

    const refreshPreferences = async (userData) => {
        try {
            const dGetPref = await getPreferences({
                authorId: userData.id,
                id: userData.id,
                type: "Docappy",
                practitionerRoleId: userData.practitionerRoleId
            })

            if (!dGetPref?.success) {
                throw new Error(dGetPref?.error ?? "Error graphql operation")
            }

            setUserPreferences(dGetPref.data)

            // Display "wizard" if needed
            if (!displaySpecialScreen.hasRefreshedSpecialScreens) {
                const orgConfigScreen = dGetPref.data.organizationPreferences ? !dGetPref.data.organizationPreferences.hasFilledConfigurationHelper : false
                const practitionerRoleConfigScreen = dGetPref.data.practitionerRolePreferences ? !dGetPref.data.practitionerRolePreferences.hasFilledConfigurationHelper : false
                // Check if user has accepted cookies
                const consentCookie = localStorage.getItem('cookieConsent');

                setDisplaySpecialScreen({
                    hasRefreshedSpecialScreens: true,
                    // Only display organizationConfiguration screen if user is manager or owner (taken care of in DoctorHomePage)
                    organizationConfiguration: orgConfigScreen,
                    practitionerRoleConfiguration: practitionerRoleConfigScreen,
                    cookieConsentBanner: !consentCookie || !["necessary", "all"].includes(consentCookie)
                })
            }
        }
        catch (e) {
        }
    }

    const setUserPreferencesData = useCallback(async ({
        newPreferences,
        type,
        id,
        authorId,
        showNotification = true
    }) => {
        try {
            let d = await setPreferences({
                authorId: authorId,
                newPreferences: newPreferences,
                type: type,
                id: id,
            })

            if (!d || !d.success) {
                throw new Error(d ? d.error : "Error graphql operation")
            }

            if (showNotification) {
                setPageNotification({
                    isNotification: true,
                    type: "ok",
                    message: translation.preferencesChanged,
                    shouldAutomaticallyClose: true
                })
            }

            refreshPreferences(userData)
        }
        catch (e) {
            setPageNotification({
                isNotification: true,
                type: "error",
                message: translation.errorPreferences,
                shouldAutomaticallyClose: true
            })
        }
        // eslint-disable-next-line
    }, [userData, displaySpecialScreen])

    useEffect(() => {
        if (userData.id) {
            refreshPreferences(userData)
        }
        // eslint-disable-next-line
    }, [userData])


    return (
        <UserPreferencesContext.Provider value={{
            userPreferences,
            setUserPreferences: setUserPreferencesData,
            refreshPreferences: () => { refreshPreferences(userData) }
        }}>
            {props.children}
        </UserPreferencesContext.Provider>
    )
}

export default UserPreferencesProvider