import React from 'react'

import { useAuth } from './context/auth'
import useSendNotificationCountdown from './hooks/useSendNotificationCountdown'
import useGetDoctorEventForNotification from './hooks/componentsHelper/useGetDoctorEventForNotification';

// Component to show automatically notification pop-up before an event happens (appointment, ..)
function EventReminder(props) {
    const { userData } = useAuth()
    const { doctorEventList } = useGetDoctorEventForNotification({ practitionerRoleId: userData.practitionerRoleId })
    useSendNotificationCountdown({ eventList: doctorEventList })

    return (
        <>
            {props.children}
        </>
    )
}

export default EventReminder