// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: { shouldRefreshData: Bool!}

// TODO move to /identity

export const postCheckAuthToken = async (data) => {
    return axiosWrapper({
        data,
        method: "post",
        url: "identity/checkAuthToken"
    })
}