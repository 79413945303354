// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import { loadDateLocalization } from "./utilsDateManagement";
import { localeArray } from "./localization";

// Give breakpoints for media queries
export const handleMediaQueryBreakpoints = (screenSize, breakpoints) => {
  // Breakpoint is an array of integers
  if (!breakpoints || breakpoints.length < 1) {
    return 0;
  }
  const width = screenSize.width;
  let orderedBreakpoints = breakpoints.sort((a, b) => {
    return a - b;
  });
  for (let i = 0; i < orderedBreakpoints.length; i++) {
    if (width <= orderedBreakpoints[i]) {
      return i;
    }
  }
  return orderedBreakpoints.length;
};

//Build a dateData object in the selected language
export let dateObject = (language) => {
  let dateMonth = require(`../data/date/date_${language}.json`);

  let dateDay = {
    name: "Date day",
    selectOptionsValue: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    selectOptionsLabel: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
  };
  let currentYear = new Date().getFullYear();

  let dateYear = {
    name: "Date year",
    selectOptionsLabel: Array.from(new Array(120), (x, i) => String(currentYear - i)),
    selectOptionsValue: Array.from(new Array(120), (x, i) => currentYear - 1900 - i),
  };

  let dateObject = [dateDay, dateMonth, dateYear];

  return dateObject;
};

//Format date to a readable dd-MMM-yyyy format
export let formatDate = (date, locale = "en-US") => {
  if (date !== undefined && date !== "") {
    const myDate = new Date(date);
    const months = loadDateLocalization(locale).month;
    const month = months[myDate.getMonth()].slice(0, 3);
    const str = myDate.getDate() + "-" + month + "-" + myDate.getFullYear();
    return str;
  }
  return "";
};

export const filtersNullArray = (array) => {
  return array.filter((el) => {
    return el != null;
  });
};

export const filterNullObject = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

//Filter items of an array, only return items with 'property' having value that pass filter against 'element'
//non-case sensitive
export const filterArrayOfObjectAgainstPropertyValue = (element, array, property) => {
  if (element.length === 0 || array.length < 1) {
    return array;
  }

  if (!property || property.length < 1) {
    return [];
  }

  let filteredArray = array.filter((item) => {
    let elementPassFilter = `${item[property]}`.toString().toLowerCase().includes(element.toLowerCase());
    return elementPassFilter;
  });
  return filteredArray;
};

export let isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export let isArrayEmpty = (arr) => {
  if (Array.isArray(arr) && arr.length === 0) {
    return true;
  }
  if (!Array.isArray(arr)) {
    return false;
  } else {
    return arr.every(isArrayEmpty);
  }
};

export let isArray = (arr) => {
  // only implement if no native implementation is available
  if (typeof Array.isArray === "undefined") {
    Array.isArray = function (arr) {
      return Object.prototype.toString.call(arr) === "[object Array]";
    };
  }

  return Array.isArray(arr);
};

export let loadTranslation = (locale, component) => {
  if (localeArray.includes(locale)) {
    return require(`../data/locale/${locale}/${component}.json`);
  }
  return require(`../data/locale/en-us/${component}.json`);
};

export var arraysMatch = function (arr1, arr2) {
  if (!arr1 || !arr2 || !isArray(arr1) || !isArray(arr2)) {
    return false;
  }
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) {
    return false;
  }
  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    //If items are arrays, recursively check if they match
    if (typeof arr1[i] === "object" && typeof arr2[i] === "object") {
      if (!arraysMatch(arr1[i], arr2[i])) {
        return false;
      }
    } else {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
  }

  // Otherwise, return true
  return true;
};

export var objectMatch = function (obj1, obj2) {
  if (!obj1 || !obj2 || obj1 === {} || obj2 === {}) {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj1);

  if (!arraysMatch(keys1, keys2)) {
    return false;
  }

  let match = true;

  for (let index in keys1) {
    let property = keys1[index];
    if (!(obj1[property] === obj2[property])) {
      match = false;
      break;
    }
  }

  return match;
};

//Return a list of node items. If only one item, return the item
export var extractPropertiesAsListFromNeo4JNodeList = function (nodeList) {
  let list = [];
  for (const node in nodeList) {
    let element = nodeList[node].properties || null;
    list.push(element);
  }
  return list;
};

//Return a list of node items. If only one item, return the item
export var extractPropertiesAsObjectFromNeo4JNode = function (node) {
  let element = node[0].properties;
  const propertiesNames = Object.keys(element);

  propertiesNames.forEach((property) => {
    if (element[property].high || element[property].low) {
      element[property] = parseInt(element[property].low) + parseInt(element[property].high) * 0.1;
    }
  });

  return element;
};

//Return a sorted list of objects based on the properties passed as arguments.
//Sort by propertyA alphabetical order. For same propertyA, sort by propertyB alphabetical order
export var sortListOfObjectBasedOnPropertiesValue = function (list, propertyA, propertyB) {
  let listCopy = list;
  if (propertyB) {
    listCopy.sort((a, b) =>
      a[propertyA] > b[propertyA] ? 1 : a[propertyA] === b[propertyA] ? (a[propertyB] > b[propertyB] ? 1 : -1) : -1
    );
    return listCopy;
  }
  listCopy.sort((a, b) => (a[propertyA] > b[propertyA] ? 1 : -1));
  return listCopy;
};

//Simulate a click when user press "Enter" key
export const buildHandleEnterKeyPress =
  (onClick) =>
    ({ key }) => {
      if (key === "Enter") {
        onClick();
      }
    };

//Check if an array has duplicates
export const arrayHasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};

//Load CSS variables related to theme, need to match with App.css and tailwind.config.js
export function loadTheme(role) {
  switch (role) {
    case "Practitioner":
      document.documentElement.style.setProperty("--theme1", "#2F6673");
      document.documentElement.style.setProperty("--theme2", "#34818C");
      document.documentElement.style.setProperty("--theme3", "#FD8400");
      document.documentElement.style.setProperty("--theme4", "#FF3125");
      document.documentElement.style.setProperty("--theme5", "#FFFFB1");
      document.documentElement.style.setProperty("--hoverBackgroundTheme", "#ffffff");
      document.documentElement.style.setProperty("--backgroundTheme1", "#F0F8FF");
      document.documentElement.style.setProperty("--backgroundTheme2", "#CFDEE1");
      document.documentElement.style.setProperty("--backgroundTheme3", "#F1F5F6");
      document.documentElement.style.setProperty("--backgroundTheme4", "#ECECEC");
      break;
    case "Patient":
      document.documentElement.style.setProperty("--theme1", "#003D68");
      document.documentElement.style.setProperty("--theme2", "#0084BC");
      document.documentElement.style.setProperty("--theme3", "#008dbc");
      document.documentElement.style.setProperty("--theme4", "#F46B6B ");
      document.documentElement.style.setProperty("--theme5", "#FFFFB1");

      document.documentElement.style.setProperty("--hoverBackgroundTheme", "#ffffff");
      document.documentElement.style.setProperty("--backgroundTheme1", "#DBEEF6");
      document.documentElement.style.setProperty("--backgroundTheme2", "#CFDEE1");
      document.documentElement.style.setProperty("--backgroundTheme3", "#C5DEE9");
      document.documentElement.style.setProperty("--backgroundTheme4", "#F5F5F5");
      break;

    case "admin":
      document.documentElement.style.setProperty("--theme4", "#000000");
      break;

    // Need to mach with App.css
    default:
      document.documentElement.style.setProperty("--theme1", "#003780");
      document.documentElement.style.setProperty("--theme2", "#0062A7");
      document.documentElement.style.setProperty("--theme3", "#008dbc");
      document.documentElement.style.setProperty("--theme4", "#0062a7");
      document.documentElement.style.setProperty("--theme5", "#04558f");
      document.documentElement.style.setProperty("--backgroundTheme1", "#F1FBFF");
      break;
  }
}

//Uppercase the first leter of a string, lowercase the rest
export function capitalizeString(str) {
  if (!str || str.length < 2) {
    return null;
  }
  return str[0].toUpperCase() + str.substring(1).toLowerCase();
}

export function deepCopyArrayObject(item) {
  let itemCopy = JSON.parse(JSON.stringify(item));
  return itemCopy;
}

export function getAge(dateString) {
  const date = parseInt(dateString);
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function arrayHasObjectWithPropertyValue(array, property, value) {
  if (array.length === 0) {
    return false;
  } else return array.some((e) => e[property] === value);
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(number) {
  // eslint-disable-next-line
  const re = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d+)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i
  return re.test(number)
}

export function arrayRemoveDuplicate(arr) {
  const unique = arr.filter((v, i, a) => a.indexOf(v) === i);
  return unique
}

// Cut the array in 2 and reassemble, so that element at index "index" is not the first element
export function arrayChoseNewStartElement(arr, index) {
  if (!arr || arr.length === 0) {
    return []
  }
  if (arr.length === 1 || index === 0) {
    return arr
  }
  if (index >= arr.length) {
    return arr
  }
  let array1 = arr.slice(0, index);
  let array2 = arr.slice(index)
  return array2.concat(array1)
}

export const isElementInViewport = (el) => {

  // Special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}