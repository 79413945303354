// Copyright © FINANCE SECURITY GmbH - All rights reserved.

import React from "react";

import { loadTranslation } from "../utils/utilsFunctions";
import { LocaleContext } from "../context/locale";
import { Navigate } from "react-router-dom";

import { ReactComponent as WarningIcon } from "../utils/icons/WarningIconGray.svg";
import "./ErrorBoundary.css";
import Button from "./utils/uiKit/Button/Button";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      shouldRedirect: false,
    };
  }

  static contextType = LocaleContext;

  componentDidMount() {
    this.loadTranslation(this.context.locale);
  }

  componentDidUpdate(prevProps, prevState) {
    // Destructure the shouldRedirect property from state
    const { shouldRedirect } = this.state;

    // Check if the shouldRedirect property has changed
    if (prevState.shouldRedirect !== shouldRedirect) {
      // If it has changed, refresh page
      window.location.reload(false);
    }
  }

  loadTranslation(locale) {
    let translationFile = loadTranslation(locale, "ErrorBoundary");
    if (translationFile !== this.state.translation) {
      this.setState({
        translation: translationFile,
      });
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError && this.state.translation) {
      return (
        <div className="error-boundary-main-container tw-flex tw-flex-col">
          <div className="margin-auto tw-flex tw-flex-col">
            <WarningIcon className="error-boundary-icon" />
            <div className="error-message">
              {this.state.translation.errorMessage} <br /> {this.state.translation.errorMessage2}
            </div>
            <Button
              text={this.state.translation.backHome}
              handleClick={() => {
                this.setState({
                  shouldRedirect: true
                })
              }}
            />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
