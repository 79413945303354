// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: {
// authorId: docappyId!
// type: "Patient" || "Practitioner"
// requiredId: practitionerRoleId || null (only needed for type = "Practitioner")
// }

// User can only request their own data

export const getUserUserdata = async (data) => {
    return axiosWrapper({
        data,
        method: "get",
        url: "user/userData"
    })
}