// Copyright © FINANCE SECURITY GmbH - All rights reserved.
import { axiosWrapper } from 'api/axiosUtils'

// data: {
//     type: oneOf["one","all"], 
//     ids: {docappyId, practitionerId, patientId, practitionerRoleId}
//     notificationId: ID || null
//     authorId: practitionerRoleId! || patientId!
// }
export const postUpdateNotificationSeen = async (data) => {
    return axiosWrapper({
        data,
        method: "post",
        url: "user/notification/updateSeen"
    })
}